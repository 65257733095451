import noop from 'lodash/noop';
import { CellProps, Row } from 'react-table';
import { sendAnalytics } from '@/main/initializers/analytics';
import { Checkbox } from '@/shared/design-system/v2';
import {
  VisualizationModel,
  VisualizationType,
} from '@/shared/design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { modelAppEvents } from '../../../../../analytics/modelAppEvents';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { useAddChartsContext } from '../../../../../contexts/snippets/add-charts/AddChartsContext';
import { ModelAppStatus, ModelWithAppMetadata } from '../../../../../generated/api';
import { MODEL_APPS_TABLE_COLS } from '../../../../model-apps/ModelApps';
import { ModelAppsContainer } from '../../../../model-apps/ModelApps.container';
import { getVizIdFromVizModel } from '../hooks/util';

const getVizModel = (modelId: string): VisualizationModel => ({
  visualizationType: VisualizationType.MODEL_APPS,
  visualizationParameters: {
    modelId,
  },
});

const MODEL_APP_COL_IDS = [
  'modelAppStatus',
  'modelAppName',
  'modelClass',
  'durationInMins',
  'createdBy',
];

export const ModelAppsListWrapper = () => {
  const { selectedCharts, toggleChartSelection } = useAddChartsContext();
  const { workspaceId } = useAppMetadata();

  const columns = [
    {
      Header: ' ',
      accessor: 'modelId',
      id: 'checkbox',
      width: 44,
      canResize: false,
      disableFilters: true,
      Cell: ({ value: modelId }: CellProps<ModelWithAppMetadata>) => {
        const vizModel = getVizModel(modelId);
        const vizId = getVizIdFromVizModel(vizModel);
        return <Checkbox checked={selectedCharts.some(({ id }) => id === vizId)} onChange={noop} />;
      },
    },
    ...MODEL_APPS_TABLE_COLS.filter(({ id }) => MODEL_APP_COL_IDS.includes(id)),
  ];

  const onRowClick = (row: Row<Record<string, unknown>>) => {
    const modelId = String(row.original.modelId);
    const vizModel = getVizModel(modelId);
    const id = getVizIdFromVizModel(vizModel);
    toggleChartSelection({
      id,
      metadata: vizModel,
    });

    const status = String(row.original.modelAppStatus);
    const modelAppStatus =
      status === ModelAppStatus.Running
        ? 'LIVE'
        : status === ModelAppStatus.Stopped
        ? 'STOPPED'
        : 'IN-PROGRESS';

    sendAnalytics(
      modelAppEvents.openApp({
        modelAppId: modelId,
        modelAppStatus: modelAppStatus,
        pageSource: 'MODEL_APPS',
        workspaceId,
      }),
    );
  };

  return (
    <ModelAppsContainer height="calc(100vh - 96px)" columns={columns} onRowClick={onRowClick} />
  );
};
