/* tslint:disable */

/* eslint-disable */
// @ts-nocheck

/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/meskalero/MarkovML/implementation/web)
 */
import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'develop' | 'staging';

export const ApiKey: Record<Environment, string> = {
  production: 'b320e3e07101b56fdc68516167b4eb13',
  develop: '6f6227038f2a4b29a31ea9cb8fe19eb7',
  staging: '726475c00d7723a4051d73c1f8404d5f',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '2',
    branch: 'main',
    source: 'web',
    versionId: '743ebea9-5ab0-4a34-8ced-6a799a5c30a4',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  email?: string;
  email_domain: string;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_utm_campaign?: string;
  initial_utm_content?: string;
  initial_utm_id?: string;
  initial_utm_source?: string;
  initial_utm_term?: string;
  referrer?: any;
  referring_domain?: any;
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_source?: string;
  workspace_id?: string;
}

export interface AppLoadedProperties {
  user?: {
    persona?: string;
  };
}

export interface CopyOfWorkflowsRunsRunNumberLogsClickedProperties {
  runId: string;
  workflowId: string;
  workspaceId: string;
}

export interface CopyeditAddDictionaryClickedProperties {
  workspaceId: string;
}

export interface CopyeditAddDictionaryWordClickedProperties {
  workspaceId: string;
}

export interface CopyeditAddDocumentProperties {
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditAllProjectsClickedProperties {
  workspaceId: string;
}

export interface CopyeditAnalyticsClickedProperties {
  workspaceId: string;
}

export interface CopyeditCustomDictionaryTabClickedProperties {
  workspaceId: string;
}

export interface CopyeditCustomRulesTabClickedProperties {
  workspaceId: string;
}

export interface CopyeditDeleteDictionaryClickedProperties {
  workspaceId: string;
}

export interface CopyeditDeleteDictionaryWordClickedProperties {
  workspaceId: string;
}

export interface CopyeditDictionaryAddCancelledProperties {
  workspaceId: string;
}

export interface CopyeditDictionaryAddedProperties {
  workspaceId: string;
}

export interface CopyeditDocAiContentDetectionClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocCheckAiContentClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocCheckPlagiarismClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocExportOriginalClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocExportRevisedClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocHistoryClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocImprovementsTabClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocPageNumberClickedProperties {
  documentId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  pageNumber: number;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocPlagiarismTabClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocReadbilityWordcountClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocReadbilityWordcountClosedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocStyleGuidesTabClickedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocSuggestionAcceptClickedProperties {
  documentId: string;
  projectId?: string;
  suggestionId: string;
  suggestionType: string;
  workspaceId: string;
}

export interface CopyeditDocSuggestionDismissClickedProperties {
  documentId: string;
  projectId?: string;
  suggestionId: string;
  suggestionType: string;
  workspaceId: string;
}

export interface CopyeditDocVersionNumberClickedProperties {
  documentId: string;
  projectId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  versionNumber: number;
  workspaceId: string;
}

export interface CopyeditDocumentCreatedProperties {
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocumentCreationCancelledProperties {
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocumentDeleteCancelProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocumentDeletedProperties {
  documentId: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocumentInviteClickedProperties {
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditDocumentOpenedProperties {
  documentId: string;
  documentName?: string;
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditEditDictionaryClickedProperties {
  workspaceId: string;
}

export interface CopyeditInvitedMembersListClickedProperties {
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditLaunchAppProperties {
  workspaceId: string;
}

export interface CopyeditProjectCancelProperties {
  workspaceId: string;
}

export interface CopyeditProjectClickProperties {
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditProjectCreateProperties {
  workspaceId: string;
}

export interface CopyeditProjectsBackButtonClickedProperties {
  projectId?: string;
  workspaceId: string;
}

export interface CopyeditRemoveInviteClickedProperties {
  projectId?: string;
  userId: string;
  workspaceId: string;
}

export interface CopyeditSettingsClickedProperties {
  workspaceId: string;
}

export interface CopyeditShareButtonClickedProperties {
  projectId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  userIds: string[];
  workspaceId: string;
}

export interface DashboardViewedProperties {
  user?: {
    email?: string;
    persona?: string;
  };
  workspaceId: string;
}

export interface DatasetAnalysisTriggerAnalyzersProperties {
  datasetId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numAnalyzers: number;
  workspaceId: string;
}

export interface DatasetRegisterStep1Properties {
  datasetReg: {
    manualUpload: string;
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStep2Properties {
  datasetReg: {
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStep3Properties {
  datasetReg: {
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetSubsetAddClickedProperties {
  datasets_id: string;
  datasetSubset: {
    description?: string;
    name: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterCancelProperties {
  datasetReg: {
    currentStep: string;
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStepConfirmColumnsBackProperties {
  datasetReg: {
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStepConfirmColumnsNextProperties {
  datasetReg: {
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStepDetailsFormBackProperties {
  datasetReg: {
    workflowId: string;
  };
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  workspaceId?: string;
}

export interface DatasetRegisterStepDetailsFormNextProperties {
  datasetReg: {
    workflowId: string;
  };
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  workspaceId?: string;
}

export interface DatasetRegisterStepImportDatasetProperties {
  datasetReg: {
    manualUpload: string;
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStepSelectAnalyzersBackProperties {
  datasetReg: {
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStepSelectAnalyzersNextProperties {
  datasetReg: {
    numAnalyzers: number;
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStepSelectAnalyzersSaveSelectionProperties {
  datasetReg: {
    numAnalyzers: number;
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetRegisterStepSimpleUploadProperties {
  datasetReg: {
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetsAddClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DATASET_LIST, DATASET_EMPTY, DASHBOARD_DATASET_EMPTY_PREVIEW, DASHBOARD_DATASET_PREVIEW_LIST, PREVIEW_DATASET_DETAILS_BANNER |
   */
  sourcePage?:
    | 'DATASET_LIST'
    | 'DATASET_EMPTY'
    | 'DASHBOARD_DATASET_EMPTY_PREVIEW'
    | 'DASHBOARD_DATASET_PREVIEW_LIST'
    | 'PREVIEW_DATASET_DETAILS_BANNER';
  workspaceId: string;
}

export interface DatasetsAnalysisCompletedProperties {
  analysis_completion_failed: string;
  analysis_completion_success: string;
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsAnalysisDownloadedProperties {
  analysis_type_id: string;
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsBasicanalysisSelectanalysisClickedProperties {
  analysis_type_id: string;
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsCancelRegistrationProperties {
  datasetReg: {
    workflowId: string;
  };
  workspaceId: string;
}

export interface DatasetsComparedProperties {
  workspaceId: string;
}

export interface DatasetsDetailsAppsTabClickedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsDetailsBasicanalysisTabClickedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsDetailsDataprofileTabClickedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsDetailsEmbeddingsTabClickedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsDetailsFullanalysisTabClickedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsDetailsRelabelingTabClickedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsDetailsTabClickedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsDetailsWorkflowsTabClickedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsFullanalysisNewanalysisClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  datasets_id?: string;
  workspaceId: string;
}

export interface DatasetsFullanalysisNewanalysisCompletedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsRelabelingGenerateNewClickedProperties {
  datasetId: string;
  newDatasetName: string;
  workspaceId: string;
}

export interface DatasetsRelabelingGenerateNewClosedProperties {
  datasetId: string;
  workspaceId: string;
}

export interface DatasetsRelabelingPrepareClickedProperties {
  datasetId: string;
  workspaceId: string;
}

export interface DatasetsTasksViewedProperties {
  datasets_id: string;
  workspaceId: string;
}

export interface DatasetsListItemClickedProperties {
  datasetId: string;
  isDemoArtifact?: boolean;
  workspaceId: string;
}

export interface DatasetsListViewedProperties {
  workspaceId: string;
}

export interface DatasetsViewSampleProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  workspaceId?: string;
}

export interface EvaluationListItemClickedProperties {
  evaluationId: string;
  isDemoArtifact?: boolean;
  workspaceId: string;
}

export interface EvaluationsAddedProperties {
  evaluations_id: string;
  workspaceId: string;
}

export interface EvaluationsComparedProperties {
  evaluations_id: string;
  workspaceId: string;
}

export interface EvaluationsViewedProperties {
  evaluations_id: string;
  workspaceId: string;
}

export interface EvaluationsViewSampleProperties {
  workspaceId: string;
}

export interface ExperimentListItemClickedProperties {
  experimentId: string;
  isDemoArtifact?: boolean;
  workspaceId: string;
}

export interface ExperimentsAddedProperties {
  experiments_id: string;
  workspaceId: string;
}

export interface ExperimentsViewedProperties {
  experiments_id: string;
  workspaceId: string;
}

export interface ExperimentsViewSampleProperties {
  workspaceId: string;
}

export interface GoToDatasetsListProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DASHBOARD_DATASET_PREVIEW_LIST, DATASET_DETAILS |
   */
  pageSource: 'DASHBOARD_DATASET_PREVIEW_LIST' | 'DATASET_DETAILS';
  workspaceId: string;
}

export interface InviteSendClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  emailsForInvite: string[];
  workspaceId: string;
}

export interface LandOnDatasetRegistrationProperties {
  datasetReg: {
    workflowId: string;
  };
  workspaceId: string;
}

export interface MembersAddedProperties {
  workspaceId: string;
}

export interface ModelAppCreateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SNIPPETS_LIST, SNIPPPETS_DETAILS, MODEL_LIST, DATASET_APPS, DATASET_DETAILS, MODEL_APPS, DASHBOARD |
   */
  pageSource?:
    | 'SNIPPETS_LIST'
    | 'SNIPPPETS_DETAILS'
    | 'MODEL_LIST'
    | 'DATASET_APPS'
    | 'DATASET_DETAILS'
    | 'MODEL_APPS'
    | 'DASHBOARD';
  workspaceId: string;
}

export interface ModelAppInferProperties {
  modelAppId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | FILE_INPUT, TEXT_INPUT |
   */
  modelAppInferType?: 'FILE_INPUT' | 'TEXT_INPUT';
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  models?: string[];
  workspaceId: string;
}

export interface ModelAppInferOptionsProperties {
  modelAppId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | RELABEL_RESPONSE, EXPORT_CSV |
   */
  modelAppInferOptions?: 'RELABEL_RESPONSE' | 'EXPORT_CSV';
  workspaceId: string;
}

export interface ModelAppOpenProperties {
  modelAppId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | LIVE, STOPPED, IN-PROGRESS, DRAFT |
   */
  modelAppStatus?: 'LIVE' | 'STOPPED' | 'IN-PROGRESS' | 'DRAFT';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SNIPPETS_LIST, SNIPPPETS_DETAILS, MODEL_LIST, DATASET_APPS, DATASET_DETAILS, MODEL_APPS, DASHBOARD |
   */
  pageSource?:
    | 'SNIPPETS_LIST'
    | 'SNIPPPETS_DETAILS'
    | 'MODEL_LIST'
    | 'DATASET_APPS'
    | 'DATASET_DETAILS'
    | 'MODEL_APPS'
    | 'DASHBOARD';
  workspaceId: string;
}

export interface ModelAppSidebarClickedProperties {
  modelAppId: string;
  options?: string;
  workspaceId: string;
}

export interface ModelAppViewSampleProperties {
  workspaceId: string;
}

export interface NavbarInviteMembersClickedProperties {
  workspaceId: string;
}

export interface NavbarWhatsnewClickedProperties {
  workspaceId: string;
}

export interface OnboardingStep2Properties {
  user?: {
    persona?: string;
  };
}

export interface OnboardingStep3Properties {
  user: {
    userPainPoint: string;
  };
}

export interface PaymentManageSubscriptionClickedProperties {
  workspaceId: string;
}

export interface PaymentPlanTypeClickedProperties {
  planType: string;
  workspaceId: string;
}

export interface PaymentViewPlanClickedProperties {
  workspaceId: string;
}

export interface ProjectListItemClickedProperties {
  isDemoArtifact?: boolean;
  projectId?: string;
  workspaceId: string;
}

export interface ProjectsAddClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PROJECT_LIST, PREVIEW_PROJECT_DETAILS |
   */
  pageSource: 'PROJECT_LIST' | 'PREVIEW_PROJECT_DETAILS';
  workspaceId: string;
}

export interface ProjectsAddedProperties {
  datasets_id: string;
}

export interface ProjectsViewedProperties {
  user: {
    email?: string;
    userId: string;
  };
}

export interface ProjectsViewSampleProperties {
  workspaceId: string;
}

export interface SdkSetupClickedProperties {
  sdk_already_setup: boolean;
}

export interface SdkSetupCompletedProperties {
  workspaceId: string;
}

export interface SearchAppCodeBtnClickedProperties {
  appId: string;
  workspaceId: string;
}

export interface SearchAppDeleteThreadClickedProperties {
  appId: string;
  threadId?: string;
  workspaceId: string;
}

export interface SearchAppDescriptionUpdatedProperties {
  appId: string;
  description: string;
  workspaceId: string;
}

export interface SearchAppNameUpdatedProperties {
  appId: string;
  name: string;
  workspaceId: string;
}

export interface SearchAppNewThreadClickedProperties {
  appId: string;
  workspaceId: string;
}

export interface SearchAppSendQueryProperties {
  appId: string;
  mode?: string;
  query: string;
  threadId?: string;
  workspaceId: string;
}

export interface SearchAppSettingsBtnClickedProperties {
  appId: string;
  workspaceId: string;
}

export interface SearchAppThreadClickedProperties {
  appId: string;
  threadId?: string;
  workspaceId: string;
}

export interface SnippetAddCommentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DATASETS, EXPERIMENTS, EVALUATIONS, TEXT, MODEL_APPS, MENTION, IMAGE/VIDEO, DELETE, TABLE, TEXT_FORMATTING |
   */
  snippetCommand?:
    | 'DATASETS'
    | 'EXPERIMENTS'
    | 'EVALUATIONS'
    | 'TEXT'
    | 'MODEL_APPS'
    | 'MENTION'
    | 'IMAGE/VIDEO'
    | 'DELETE'
    | 'TABLE'
    | 'TEXT_FORMATTING';
  snippetId: string;
  workspaceId: string;
}

export interface SnippetAddItemProperties {
  snippetId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  snippetItem: string[];
  workspaceId: string;
}

export interface SnippetAddSlashCommandProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DATASETS, EXPERIMENTS, EVALUATIONS, TEXT, MODEL_APPS, MENTION, IMAGE/VIDEO, DELETE, TABLE, TEXT_FORMATTING |
   */
  snippetCommand?:
    | 'DATASETS'
    | 'EXPERIMENTS'
    | 'EVALUATIONS'
    | 'TEXT'
    | 'MODEL_APPS'
    | 'MENTION'
    | 'IMAGE/VIDEO'
    | 'DELETE'
    | 'TABLE'
    | 'TEXT_FORMATTING';
  snippetId: string;
  workspaceId: string;
}

export interface SnippetChangeModeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | EDIT, VIEW |
   */
  snippetChangeMode: 'EDIT' | 'VIEW';
  snippetId: string;
  workspaceId: string;
}

export interface SnippetDeleteProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SNIPPETS_LIST, SNIPPPETS_DETAILS, MODEL_LIST, DATASET_APPS, DATASET_DETAILS, MODEL_APPS, DASHBOARD |
   */
  pageSource?:
    | 'SNIPPETS_LIST'
    | 'SNIPPPETS_DETAILS'
    | 'MODEL_LIST'
    | 'DATASET_APPS'
    | 'DATASET_DETAILS'
    | 'MODEL_APPS'
    | 'DASHBOARD';
  snippetId: string;
  workspaceId: string;
}

export interface SnippetShareButtonProperties {
  shareSnippetAcrossWorkspace?: boolean;
  shareSnippetToPublic?: boolean;
  snippetId: string;
  workspaceId: string;
}

export interface SnippetViewAllProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SNIPPETS_LIST, SNIPPPETS_DETAILS, MODEL_LIST, DATASET_APPS, DATASET_DETAILS, MODEL_APPS, DASHBOARD |
   */
  pageSource?:
    | 'SNIPPETS_LIST'
    | 'SNIPPPETS_DETAILS'
    | 'MODEL_LIST'
    | 'DATASET_APPS'
    | 'DATASET_DETAILS'
    | 'MODEL_APPS'
    | 'DASHBOARD';
  workspaceId: string;
}

export interface SnippetAddClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SNIPPETS_LIST, SNIPPPETS_DETAILS, MODEL_LIST, DATASET_APPS, DATASET_DETAILS, MODEL_APPS, DASHBOARD |
   */
  pageSource?:
    | 'SNIPPETS_LIST'
    | 'SNIPPPETS_DETAILS'
    | 'MODEL_LIST'
    | 'DATASET_APPS'
    | 'DATASET_DETAILS'
    | 'MODEL_APPS'
    | 'DASHBOARD';
  workspaceId: string;
}

export interface SnippetItemClickedProperties {
  isDemoArtifact?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SNIPPETS_LIST, SNIPPPETS_DETAILS, MODEL_LIST, DATASET_APPS, DATASET_DETAILS, MODEL_APPS, DASHBOARD |
   */
  pageSource?:
    | 'SNIPPETS_LIST'
    | 'SNIPPPETS_DETAILS'
    | 'MODEL_LIST'
    | 'DATASET_APPS'
    | 'DATASET_DETAILS'
    | 'MODEL_APPS'
    | 'DASHBOARD';
  snippetId: string;
  workspaceId: string;
}

export interface SnippetViewSampleProperties {
  workspaceId: string;
}

export interface UserSignInProperties {
  user: {
    email?: string;
    persona?: string;
    userId: string;
  };
  workspaceId: string;
}

export interface UserSignUpProperties {
  region: string;
  user: {
    email?: string;
    persona?: string;
    signup: {
      utm: {
        campaign: string;
      };
    };
    userId: string;
  };
}

export interface WorkflowAppsDeployButtonClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowAppsLiveAppButtonClickedProperties {
  workflowAppId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAddOperatorClickedProperties {
  operatorId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAddOperatorDroppedProperties {
  operatorId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAppsDeployStep1Properties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAppsDeployStep2Properties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAppsExecuteRunButtonClickedProperties {
  workflowAppId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAppsNextButtonClickedProperties {
  step: string;
  workflowAppId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAppsSidePanelNewRunClickedProperties {
  workflowAppId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAppsSidePanelRunsHistoryClickedProperties {
  workflowAppId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsAppsStepperClickedProperties {
  step: string;
  workflowAppId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsCloneWorkflowClickedProperties {
  sourceWorkflowId: string;
  workspaceId: string;
}

export interface WorkflowsCreateBlankWorkflowClickedProperties {
  source?: string;
  workspaceId: string;
}

export interface WorkflowsCreateWorkflowFromTemplateClickedProperties {
  templateId: string;
  workspaceId: string;
}

export interface WorkflowsDebugRunClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsFreeTrialModalTourClickedProperties {
  workspaceId: string;
}

export interface WorkflowsGoToAllWorkflowsClickedProperties {
  workspaceId: string;
}

export interface WorkflowsHeaderSectionButtonClickedProperties {
  tab: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsLaunchSearchAppClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsLinkNodesWithEdgeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Items | 2 |
   * | Item Type | string |
   *
   * @minItems 2
   */
  nodeIds: [string, string, ...string[]];
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsNodeDeleteBtnClickedProperties {
  nodeId: string;
  operatorId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsNodeEditBtnClickedProperties {
  nodeId: string;
  operatorId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsNodeLogsClickedProperties {
  nodeId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsNodeOutputPreviewClickedProperties {
  nodeId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsOnboardingStep2Properties {
  userIntent: string;
}

export interface WorkflowsOperatorExampleHoverProperties {
  operatorId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsOperatorNodeClickedProperties {
  operatorId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsOperatorNodesListSearchProperties {
  searchQuery: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsOperatorNodesListTabClickedProperties {
  tab: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsPublishWorkflowClickedProperties {
  runType?: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunPublishedWorkflowClickedProperties {
  runType?: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsNodeLogsModalClosedProperties {
  runId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsNodeLogsModalOpenedProperties {
  runId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsNodeLogsTabClickedProperties {
  operatorId: string;
  runId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsNodePreviewTabClickedProperties {
  operatorId: string;
  runId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsOperatorViewConfigClickedProperties {
  operatorId: string;
  runId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsRunClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsRunFlowClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsRunModalClosedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsRunNumberCardClickedProperties {
  runId: string;
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsRunsHistoryTabClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsRunsTabitemClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsScheduleButtonClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsScheduleModalClosedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsScheduleModalOpenedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsRunsUpcomingTabitemClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface WorkflowsViewLastDebugRunClickedProperties {
  workflowId: string;
  workspaceId: string;
}

export interface PublicDataAnalyticsSuggestedQuestionClickedProperties {
  question: string;
}

export interface PublicDataAnalyticsModalBannerSignUpClickedProperties {}

export interface PublicDataAnalyticsHeaderBannerSignUpClickedProperties {}

export interface PublicDataAnalyticsHeaderSignUpClickedProperties {}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AppLoaded implements BaseEvent {
  event_type = 'app_loaded';

  constructor(public event_properties?: AppLoadedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyOfWorkflowsRunsRunNumberLogsClicked implements BaseEvent {
  event_type = 'Copy of workflows-runs_run-number-logs-clicked';

  constructor(public event_properties: CopyOfWorkflowsRunsRunNumberLogsClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditAddDictionaryClicked implements BaseEvent {
  event_type = 'copyedit-add_dictionary_clicked';

  constructor(public event_properties: CopyeditAddDictionaryClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditAddDictionaryWordClicked implements BaseEvent {
  event_type = 'copyedit-add_dictionary_word_clicked';

  constructor(public event_properties: CopyeditAddDictionaryWordClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditAddDocument implements BaseEvent {
  event_type = 'copyedit-add_document';

  constructor(public event_properties: CopyeditAddDocumentProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditAllProjectsClicked implements BaseEvent {
  event_type = 'copyedit-all_projects_clicked';

  constructor(public event_properties: CopyeditAllProjectsClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditAnalyticsClicked implements BaseEvent {
  event_type = 'copyedit-analytics_clicked';

  constructor(public event_properties: CopyeditAnalyticsClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditCustomDictionaryTabClicked implements BaseEvent {
  event_type = 'copyedit-custom_dictionary_tab_clicked';

  constructor(public event_properties: CopyeditCustomDictionaryTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditCustomRulesTabClicked implements BaseEvent {
  event_type = 'copyedit-custom_rules_tab_clicked';

  constructor(public event_properties: CopyeditCustomRulesTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDeleteDictionaryClicked implements BaseEvent {
  event_type = 'copyedit-delete_dictionary_clicked';

  constructor(public event_properties: CopyeditDeleteDictionaryClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDeleteDictionaryWordClicked implements BaseEvent {
  event_type = 'copyedit-delete_dictionary_word_clicked';

  constructor(public event_properties: CopyeditDeleteDictionaryWordClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDictionaryAddCancelled implements BaseEvent {
  event_type = 'copyedit-dictionary_add_cancelled';

  constructor(public event_properties: CopyeditDictionaryAddCancelledProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDictionaryAdded implements BaseEvent {
  event_type = 'copyedit-dictionary_added';

  constructor(public event_properties: CopyeditDictionaryAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocAiContentDetectionClicked implements BaseEvent {
  event_type = 'copyedit-doc_ai_content_detection_clicked';

  constructor(public event_properties: CopyeditDocAiContentDetectionClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocCheckAiContentClicked implements BaseEvent {
  event_type = 'copyedit-doc_check_ai_content_clicked';

  constructor(public event_properties: CopyeditDocCheckAiContentClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocCheckPlagiarismClicked implements BaseEvent {
  event_type = 'copyedit-doc_check_plagiarism_clicked';

  constructor(public event_properties: CopyeditDocCheckPlagiarismClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocExportOriginalClicked implements BaseEvent {
  event_type = 'copyedit-doc_export_original_clicked';

  constructor(public event_properties: CopyeditDocExportOriginalClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocExportRevisedClicked implements BaseEvent {
  event_type = 'copyedit-doc_export_revised_clicked';

  constructor(public event_properties: CopyeditDocExportRevisedClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocHistoryClicked implements BaseEvent {
  event_type = 'copyedit-doc_history_clicked';

  constructor(public event_properties: CopyeditDocHistoryClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocImprovementsTabClicked implements BaseEvent {
  event_type = 'copyedit-doc_improvements_tab_clicked';

  constructor(public event_properties: CopyeditDocImprovementsTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocPageNumberClicked implements BaseEvent {
  event_type = 'copyedit-doc_page_number_clicked';

  constructor(public event_properties: CopyeditDocPageNumberClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocPlagiarismTabClicked implements BaseEvent {
  event_type = 'copyedit-doc_plagiarism_tab_clicked';

  constructor(public event_properties: CopyeditDocPlagiarismTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocReadbilityWordcountClicked implements BaseEvent {
  event_type = 'copyedit-doc_readbility_wordcount_clicked';

  constructor(public event_properties: CopyeditDocReadbilityWordcountClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocReadbilityWordcountClosed implements BaseEvent {
  event_type = 'copyedit-doc_readbility_wordcount_closed';

  constructor(public event_properties: CopyeditDocReadbilityWordcountClosedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocStyleGuidesTabClicked implements BaseEvent {
  event_type = 'copyedit-doc_style_guides_tab_clicked';

  constructor(public event_properties: CopyeditDocStyleGuidesTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocSuggestionAcceptClicked implements BaseEvent {
  event_type = 'copyedit-doc_suggestion_accept_clicked';

  constructor(public event_properties: CopyeditDocSuggestionAcceptClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocSuggestionDismissClicked implements BaseEvent {
  event_type = 'copyedit-doc_suggestion_dismiss_clicked';

  constructor(public event_properties: CopyeditDocSuggestionDismissClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocVersionNumberClicked implements BaseEvent {
  event_type = 'copyedit-doc_version_number_clicked';

  constructor(public event_properties: CopyeditDocVersionNumberClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocumentCreated implements BaseEvent {
  event_type = 'copyedit-document_created';

  constructor(public event_properties: CopyeditDocumentCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocumentCreationCancelled implements BaseEvent {
  event_type = 'copyedit-document_creation_cancelled';

  constructor(public event_properties: CopyeditDocumentCreationCancelledProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocumentDeleteCancel implements BaseEvent {
  event_type = 'copyedit-document_delete_cancel';

  constructor(public event_properties: CopyeditDocumentDeleteCancelProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocumentDeleted implements BaseEvent {
  event_type = 'copyedit-document_deleted';

  constructor(public event_properties: CopyeditDocumentDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocumentInviteClicked implements BaseEvent {
  event_type = 'copyedit-document_invite_clicked';

  constructor(public event_properties: CopyeditDocumentInviteClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditDocumentOpened implements BaseEvent {
  event_type = 'copyedit-document_opened';

  constructor(public event_properties: CopyeditDocumentOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditEditDictionaryClicked implements BaseEvent {
  event_type = 'copyedit-edit_dictionary_clicked';

  constructor(public event_properties: CopyeditEditDictionaryClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditInvitedMembersListClicked implements BaseEvent {
  event_type = 'copyedit-invited_members_list_clicked';

  constructor(public event_properties: CopyeditInvitedMembersListClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditLaunchApp implements BaseEvent {
  event_type = 'copyedit-launch_app';

  constructor(public event_properties: CopyeditLaunchAppProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditProjectCancel implements BaseEvent {
  event_type = 'copyedit-project_cancel';

  constructor(public event_properties: CopyeditProjectCancelProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditProjectClick implements BaseEvent {
  event_type = 'copyedit-project_click';

  constructor(public event_properties: CopyeditProjectClickProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditProjectCreate implements BaseEvent {
  event_type = 'copyedit-project_create';

  constructor(public event_properties: CopyeditProjectCreateProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditProjectsBackButtonClicked implements BaseEvent {
  event_type = 'copyedit-projects_back_button_clicked';

  constructor(public event_properties: CopyeditProjectsBackButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditRemoveInviteClicked implements BaseEvent {
  event_type = 'copyedit-remove_invite_clicked';

  constructor(public event_properties: CopyeditRemoveInviteClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditSettingsClicked implements BaseEvent {
  event_type = 'copyedit-settings_clicked';

  constructor(public event_properties: CopyeditSettingsClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CopyeditShareButtonClicked implements BaseEvent {
  event_type = 'copyedit-share_button_clicked';

  constructor(public event_properties: CopyeditShareButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardViewed implements BaseEvent {
  event_type = 'dashboard_viewed';

  constructor(public event_properties: DashboardViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetAnalysisTriggerAnalyzers implements BaseEvent {
  event_type = 'dataset_analysis-trigger_analyzers';

  constructor(public event_properties: DatasetAnalysisTriggerAnalyzersProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetDetailsViewed implements BaseEvent {
  event_type = 'dataset_details_viewed';
}

export class DatasetRegisterStep1 implements BaseEvent {
  event_type = 'dataset_register_step1';

  constructor(public event_properties: DatasetRegisterStep1Properties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStep2 implements BaseEvent {
  event_type = 'dataset_register_step2';

  constructor(public event_properties: DatasetRegisterStep2Properties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStep3 implements BaseEvent {
  event_type = 'dataset_register_step3';

  constructor(public event_properties: DatasetRegisterStep3Properties) {
    this.event_properties = event_properties;
  }
}

export class DatasetSubsetAddClicked implements BaseEvent {
  event_type = 'dataset_subset_add_clicked';

  constructor(public event_properties: DatasetSubsetAddClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterCancel implements BaseEvent {
  event_type = 'dataset-register-cancel';

  constructor(public event_properties: DatasetRegisterCancelProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepConfirmColumnsBack implements BaseEvent {
  event_type = 'dataset-register-step-confirm_columns-back';

  constructor(public event_properties: DatasetRegisterStepConfirmColumnsBackProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepConfirmColumnsNext implements BaseEvent {
  event_type = 'dataset-register-step-confirm_columns-next';

  constructor(public event_properties: DatasetRegisterStepConfirmColumnsNextProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepDetailsFormBack implements BaseEvent {
  event_type = 'dataset-register-step-details_form-back';

  constructor(public event_properties: DatasetRegisterStepDetailsFormBackProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepDetailsFormNext implements BaseEvent {
  event_type = 'dataset-register-step-details_form-next';

  constructor(public event_properties: DatasetRegisterStepDetailsFormNextProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepImportDataset implements BaseEvent {
  event_type = 'dataset-register-step-import_dataset';

  constructor(public event_properties: DatasetRegisterStepImportDatasetProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepSelectAnalyzersBack implements BaseEvent {
  event_type = 'dataset-register-step-select_analyzers-back';

  constructor(public event_properties: DatasetRegisterStepSelectAnalyzersBackProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepSelectAnalyzersNext implements BaseEvent {
  event_type = 'dataset-register-step-select_analyzers-next';

  constructor(public event_properties: DatasetRegisterStepSelectAnalyzersNextProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepSelectAnalyzersSaveSelection implements BaseEvent {
  event_type = 'dataset-register-step-select_analyzers-save_selection';

  constructor(public event_properties: DatasetRegisterStepSelectAnalyzersSaveSelectionProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetRegisterStepSimpleUpload implements BaseEvent {
  event_type = 'dataset-register-step-simple_upload';

  constructor(public event_properties: DatasetRegisterStepSimpleUploadProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsAddClicked implements BaseEvent {
  event_type = 'datasets_add_clicked';

  constructor(public event_properties: DatasetsAddClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsAnalysisCompleted implements BaseEvent {
  event_type = 'datasets_analysis_completed';

  constructor(public event_properties: DatasetsAnalysisCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsAnalysisDownloaded implements BaseEvent {
  event_type = 'datasets_analysis_downloaded';

  constructor(public event_properties: DatasetsAnalysisDownloadedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsBasicanalysisSelectanalysisClicked implements BaseEvent {
  event_type = 'datasets_basicanalysis_selectanalysis_clicked';

  constructor(public event_properties: DatasetsBasicanalysisSelectanalysisClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsCancelRegistration implements BaseEvent {
  event_type = 'datasets_cancel_registration';

  constructor(public event_properties: DatasetsCancelRegistrationProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsCompared implements BaseEvent {
  event_type = 'datasets_compared';

  constructor(public event_properties: DatasetsComparedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsDetailsAppsTabClicked implements BaseEvent {
  event_type = 'datasets_details_apps_tab_clicked';

  constructor(public event_properties: DatasetsDetailsAppsTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsDetailsBasicanalysisTabClicked implements BaseEvent {
  event_type = 'datasets_details_basicanalysis_tab_clicked';

  constructor(public event_properties: DatasetsDetailsBasicanalysisTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsDetailsDataprofileTabClicked implements BaseEvent {
  event_type = 'datasets_details_dataprofile_tab_clicked';

  constructor(public event_properties: DatasetsDetailsDataprofileTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsDetailsEmbeddingsTabClicked implements BaseEvent {
  event_type = 'datasets_details_embeddings_tab_clicked';

  constructor(public event_properties: DatasetsDetailsEmbeddingsTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsDetailsFullanalysisTabClicked implements BaseEvent {
  event_type = 'datasets_details_fullanalysis_tab_clicked';

  constructor(public event_properties: DatasetsDetailsFullanalysisTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsDetailsRelabelingTabClicked implements BaseEvent {
  event_type = 'datasets_details_relabeling_tab_clicked';

  constructor(public event_properties: DatasetsDetailsRelabelingTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsDetailsTabClicked implements BaseEvent {
  event_type = 'datasets_details_tab_clicked';

  constructor(public event_properties: DatasetsDetailsTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsDetailsWorkflowsTabClicked implements BaseEvent {
  event_type = 'datasets_details_workflows_tab_clicked';

  constructor(public event_properties: DatasetsDetailsWorkflowsTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsFullanalysisNewanalysisClicked implements BaseEvent {
  event_type = 'datasets_fullanalysis_newanalysis_clicked';

  constructor(public event_properties: DatasetsFullanalysisNewanalysisClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsFullanalysisNewanalysisCompleted implements BaseEvent {
  event_type = 'datasets_fullanalysis_newanalysis_completed';

  constructor(public event_properties: DatasetsFullanalysisNewanalysisCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsRelabelingGenerateNewClicked implements BaseEvent {
  event_type = 'datasets_relabeling_generate_new_clicked';

  constructor(public event_properties: DatasetsRelabelingGenerateNewClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsRelabelingGenerateNewClosed implements BaseEvent {
  event_type = 'datasets_relabeling_generate_new_closed';

  constructor(public event_properties: DatasetsRelabelingGenerateNewClosedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsRelabelingPrepareClicked implements BaseEvent {
  event_type = 'datasets_relabeling_prepare_clicked';

  constructor(public event_properties: DatasetsRelabelingPrepareClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsTasksViewed implements BaseEvent {
  event_type = 'datasets_tasks_viewed';

  constructor(public event_properties: DatasetsTasksViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsListItemClicked implements BaseEvent {
  event_type = 'datasets-list-item-clicked';

  constructor(public event_properties: DatasetsListItemClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsListViewed implements BaseEvent {
  event_type = 'datasets-list:viewed';

  constructor(public event_properties: DatasetsListViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class DatasetsViewSample implements BaseEvent {
  event_type = 'datasets-view-sample';

  constructor(public event_properties?: DatasetsViewSampleProperties) {
    this.event_properties = event_properties;
  }
}

export class DocsButtonClicked implements BaseEvent {
  event_type = 'docs_button_clicked';
}

export class EvaluationListItemClicked implements BaseEvent {
  event_type = 'evaluation-list-item-clicked';

  constructor(public event_properties: EvaluationListItemClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class EvaluationsAdded implements BaseEvent {
  event_type = 'evaluations_added';

  constructor(public event_properties: EvaluationsAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class EvaluationsCompared implements BaseEvent {
  event_type = 'evaluations_compared';

  constructor(public event_properties: EvaluationsComparedProperties) {
    this.event_properties = event_properties;
  }
}

export class EvaluationsViewed implements BaseEvent {
  event_type = 'evaluations_viewed';

  constructor(public event_properties: EvaluationsViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class EvaluationsViewSample implements BaseEvent {
  event_type = 'evaluations-view-sample';

  constructor(public event_properties: EvaluationsViewSampleProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperimentListItemClicked implements BaseEvent {
  event_type = 'experiment-list-item-clicked';

  constructor(public event_properties: ExperimentListItemClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperimentsAdded implements BaseEvent {
  event_type = 'experiments_added';

  constructor(public event_properties: ExperimentsAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperimentsViewed implements BaseEvent {
  event_type = 'experiments_viewed';

  constructor(public event_properties: ExperimentsViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperimentsViewSample implements BaseEvent {
  event_type = 'experiments-view-sample';

  constructor(public event_properties: ExperimentsViewSampleProperties) {
    this.event_properties = event_properties;
  }
}

export class GoToDatasetsList implements BaseEvent {
  event_type = 'go-to-datasets-list';

  constructor(public event_properties: GoToDatasetsListProperties) {
    this.event_properties = event_properties;
  }
}

export class InviteSendClicked implements BaseEvent {
  event_type = 'invite-send-clicked';

  constructor(public event_properties: InviteSendClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class LandOnDatasetRegistration implements BaseEvent {
  event_type = 'Land on Dataset Registration';

  constructor(public event_properties: LandOnDatasetRegistrationProperties) {
    this.event_properties = event_properties;
  }
}

export class MembersAdded implements BaseEvent {
  event_type = 'members_added';

  constructor(public event_properties: MembersAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class ModelAppCreate implements BaseEvent {
  event_type = 'model_app_create';

  constructor(public event_properties: ModelAppCreateProperties) {
    this.event_properties = event_properties;
  }
}

export class ModelAppInfer implements BaseEvent {
  event_type = 'model_app_infer';

  constructor(public event_properties: ModelAppInferProperties) {
    this.event_properties = event_properties;
  }
}

export class ModelAppInferOptions implements BaseEvent {
  event_type = 'model_app_infer_options';

  constructor(public event_properties: ModelAppInferOptionsProperties) {
    this.event_properties = event_properties;
  }
}

export class ModelAppOpen implements BaseEvent {
  event_type = 'model_app_open';

  constructor(public event_properties: ModelAppOpenProperties) {
    this.event_properties = event_properties;
  }
}

export class ModelAppSidebarClicked implements BaseEvent {
  event_type = 'model_app_sidebar_clicked';

  constructor(public event_properties: ModelAppSidebarClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ModelAppViewSample implements BaseEvent {
  event_type = 'model-app-view-sample';

  constructor(public event_properties: ModelAppViewSampleProperties) {
    this.event_properties = event_properties;
  }
}

export class NavbarDashboardClicked implements BaseEvent {
  event_type = 'navbar_dashboard_clicked';
}

export class NavbarDatasetsClicked implements BaseEvent {
  event_type = 'navbar_datasets_clicked';
}

export class NavbarEvaluationClicked implements BaseEvent {
  event_type = 'navbar_evaluation_clicked';
}

export class NavbarExperimentClicked implements BaseEvent {
  event_type = 'navbar_experiment_clicked';
}

export class NavbarInviteMembersClicked implements BaseEvent {
  event_type = 'navbar_invite_members_clicked';

  constructor(public event_properties: NavbarInviteMembersClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class NavbarMenuClicked implements BaseEvent {
  event_type = 'navbar_menu_clicked';
}

export class NavbarModelClicked implements BaseEvent {
  event_type = 'navbar_model_clicked';
}

export class NavbarNotebookClicked implements BaseEvent {
  event_type = 'navbar_notebook_clicked';
}

export class NavbarProjectsClicked implements BaseEvent {
  event_type = 'navbar_projects_clicked';
}

export class NavbarRunsClicked implements BaseEvent {
  event_type = 'navbar_runs_clicked';
}

export class NavbarSettingsClicked implements BaseEvent {
  event_type = 'navbar_settings_clicked';
}

export class NavbarSnippetsClicked implements BaseEvent {
  event_type = 'navbar_snippets_clicked';
}

export class NavbarWhatsnewClicked implements BaseEvent {
  event_type = 'navbar_whatsnew_clicked';

  constructor(public event_properties: NavbarWhatsnewClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingStep1 implements BaseEvent {
  event_type = 'Onboarding step 1';
}

export class OnboardingStep2 implements BaseEvent {
  event_type = 'Onboarding step 2';

  constructor(public event_properties?: OnboardingStep2Properties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingStep3 implements BaseEvent {
  event_type = 'Onboarding step 3';

  constructor(public event_properties: OnboardingStep3Properties) {
    this.event_properties = event_properties;
  }
}

export class PaymentManageSubscriptionClicked implements BaseEvent {
  event_type = 'payment-manage_subscription_clicked';

  constructor(public event_properties: PaymentManageSubscriptionClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentPlanTypeClicked implements BaseEvent {
  event_type = 'payment-plan_type_clicked';

  constructor(public event_properties: PaymentPlanTypeClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentViewPlanClicked implements BaseEvent {
  event_type = 'payment-view_plan_clicked';

  constructor(public event_properties: PaymentViewPlanClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ProjectListItemClicked implements BaseEvent {
  event_type = 'project-list-item-clicked';

  constructor(public event_properties: ProjectListItemClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ProjectsAddClicked implements BaseEvent {
  event_type = 'projects_add_clicked';

  constructor(public event_properties: ProjectsAddClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ProjectsAdded implements BaseEvent {
  event_type = 'projects_added';

  constructor(public event_properties: ProjectsAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class ProjectsViewed implements BaseEvent {
  event_type = 'projects_viewed';

  constructor(public event_properties: ProjectsViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class ProjectsViewSample implements BaseEvent {
  event_type = 'projects-view-sample';

  constructor(public event_properties: ProjectsViewSampleProperties) {
    this.event_properties = event_properties;
  }
}

export class SdkSetupClicked implements BaseEvent {
  event_type = 'sdk_setup_clicked';

  constructor(public event_properties: SdkSetupClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SdkSetupCompleted implements BaseEvent {
  event_type = 'sdk_setup_completed';

  constructor(public event_properties: SdkSetupCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAppCodeBtnClicked implements BaseEvent {
  event_type = 'search_app-code_btn_clicked';

  constructor(public event_properties: SearchAppCodeBtnClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAppDeleteThreadClicked implements BaseEvent {
  event_type = 'search_app-delete_thread_clicked';

  constructor(public event_properties: SearchAppDeleteThreadClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAppDescriptionUpdated implements BaseEvent {
  event_type = 'search_app-description_updated';

  constructor(public event_properties: SearchAppDescriptionUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAppNameUpdated implements BaseEvent {
  event_type = 'search_app-name_updated';

  constructor(public event_properties: SearchAppNameUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAppNewThreadClicked implements BaseEvent {
  event_type = 'search_app-new_thread_clicked';

  constructor(public event_properties: SearchAppNewThreadClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAppSendQuery implements BaseEvent {
  event_type = 'search_app-send_query';

  constructor(public event_properties: SearchAppSendQueryProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAppSettingsBtnClicked implements BaseEvent {
  event_type = 'search_app-settings_btn_clicked';

  constructor(public event_properties: SearchAppSettingsBtnClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SearchAppThreadClicked implements BaseEvent {
  event_type = 'search_app-thread_clicked';

  constructor(public event_properties: SearchAppThreadClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetAddComment implements BaseEvent {
  event_type = 'snippet_add_comment';

  constructor(public event_properties: SnippetAddCommentProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetAddItem implements BaseEvent {
  event_type = 'snippet_add_item';

  constructor(public event_properties: SnippetAddItemProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetAddSlashCommand implements BaseEvent {
  event_type = 'snippet_add_slash_command';

  constructor(public event_properties: SnippetAddSlashCommandProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetChangeMode implements BaseEvent {
  event_type = 'snippet_change_mode';

  constructor(public event_properties: SnippetChangeModeProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetDelete implements BaseEvent {
  event_type = 'snippet_delete';

  constructor(public event_properties: SnippetDeleteProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetShareButton implements BaseEvent {
  event_type = 'snippet_share_button';

  constructor(public event_properties: SnippetShareButtonProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetViewAll implements BaseEvent {
  event_type = 'snippet_view_all';

  constructor(public event_properties: SnippetViewAllProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetAddClicked implements BaseEvent {
  event_type = 'snippet-add-clicked';

  constructor(public event_properties: SnippetAddClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetItemClicked implements BaseEvent {
  event_type = 'snippet-item-clicked';

  constructor(public event_properties: SnippetItemClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SnippetViewSample implements BaseEvent {
  event_type = 'snippet-view-sample';

  constructor(public event_properties: SnippetViewSampleProperties) {
    this.event_properties = event_properties;
  }
}

export class SwitchWorkspaceClicked implements BaseEvent {
  event_type = 'switch_workspace_clicked';
}

export class UserSignIn implements BaseEvent {
  event_type = 'User Sign in';

  constructor(public event_properties: UserSignInProperties) {
    this.event_properties = event_properties;
  }
}

export class UserSignUp implements BaseEvent {
  event_type = 'User Sign-up';

  constructor(public event_properties: UserSignUpProperties) {
    this.event_properties = event_properties;
  }
}

export class WelcomeModalPrimaryCtaClicked implements BaseEvent {
  event_type = 'welcome_modal-primary-cta-clicked';
}

export class WelcomeModalCloseClicked implements BaseEvent {
  event_type = 'welcome-modal-close-clicked';
}

export class WorkflowAppsDeployButtonClicked implements BaseEvent {
  event_type = 'workflow-apps_deploy_button_clicked';

  constructor(public event_properties: WorkflowAppsDeployButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowAppsLiveAppButtonClicked implements BaseEvent {
  event_type = 'workflow-apps_live_app_button_clicked';

  constructor(public event_properties: WorkflowAppsLiveAppButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAddOperatorClicked implements BaseEvent {
  event_type = 'workflows-add_operator_clicked';

  constructor(public event_properties: WorkflowsAddOperatorClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAddOperatorDropped implements BaseEvent {
  event_type = 'workflows-add_operator_dropped';

  constructor(public event_properties: WorkflowsAddOperatorDroppedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAppsDeployStep1 implements BaseEvent {
  event_type = 'workflows-apps_deploy_step1';

  constructor(public event_properties: WorkflowsAppsDeployStep1Properties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAppsDeployStep2 implements BaseEvent {
  event_type = 'workflows-apps_deploy_step2';

  constructor(public event_properties: WorkflowsAppsDeployStep2Properties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAppsExecuteRunButtonClicked implements BaseEvent {
  event_type = 'workflows-apps_execute_run_button_clicked';

  constructor(public event_properties: WorkflowsAppsExecuteRunButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAppsNextButtonClicked implements BaseEvent {
  event_type = 'workflows-apps_next_button_clicked';

  constructor(public event_properties: WorkflowsAppsNextButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAppsSidePanelNewRunClicked implements BaseEvent {
  event_type = 'workflows-apps_side_panel_new_run_clicked';

  constructor(public event_properties: WorkflowsAppsSidePanelNewRunClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAppsSidePanelRunsHistoryClicked implements BaseEvent {
  event_type = 'workflows-apps_side_panel_runs_history_clicked';

  constructor(public event_properties: WorkflowsAppsSidePanelRunsHistoryClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsAppsStepperClicked implements BaseEvent {
  event_type = 'workflows-apps_stepper-clicked';

  constructor(public event_properties: WorkflowsAppsStepperClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsCloneWorkflowClicked implements BaseEvent {
  event_type = 'workflows-clone_workflow_clicked';

  constructor(public event_properties: WorkflowsCloneWorkflowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsCreateBlankWorkflowClicked implements BaseEvent {
  event_type = 'workflows-create_blank_workflow_clicked';

  constructor(public event_properties: WorkflowsCreateBlankWorkflowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsCreateWorkflowFromTemplateClicked implements BaseEvent {
  event_type = 'workflows-create_workflow_from_template_clicked';

  constructor(public event_properties: WorkflowsCreateWorkflowFromTemplateClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsDebugRunClicked implements BaseEvent {
  event_type = 'workflows-debug_run_clicked';

  constructor(public event_properties: WorkflowsDebugRunClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsFreeTrialModalTourClicked implements BaseEvent {
  event_type = 'workflows-free_trial_modal_tour_clicked';

  constructor(public event_properties: WorkflowsFreeTrialModalTourClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsGoToAllWorkflowsClicked implements BaseEvent {
  event_type = 'workflows-go_to_all_workflows_clicked';

  constructor(public event_properties: WorkflowsGoToAllWorkflowsClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsHeaderSectionButtonClicked implements BaseEvent {
  event_type = 'workflows-header_section_button_clicked';

  constructor(public event_properties: WorkflowsHeaderSectionButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsLaunchSearchAppClicked implements BaseEvent {
  event_type = 'workflows-launch_search_app_clicked';

  constructor(public event_properties: WorkflowsLaunchSearchAppClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsLinkNodesWithEdge implements BaseEvent {
  event_type = 'workflows-link_nodes_with_edge';

  constructor(public event_properties: WorkflowsLinkNodesWithEdgeProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsNodeDeleteBtnClicked implements BaseEvent {
  event_type = 'workflows-node_delete_btn_clicked';

  constructor(public event_properties: WorkflowsNodeDeleteBtnClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsNodeEditBtnClicked implements BaseEvent {
  event_type = 'workflows-node_edit_btn_clicked';

  constructor(public event_properties: WorkflowsNodeEditBtnClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsNodeLogsClicked implements BaseEvent {
  event_type = 'workflows-node_logs_clicked';

  constructor(public event_properties: WorkflowsNodeLogsClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsNodeOutputPreviewClicked implements BaseEvent {
  event_type = 'workflows-node_output_preview_clicked';

  constructor(public event_properties: WorkflowsNodeOutputPreviewClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsOnboardingStep1 implements BaseEvent {
  event_type = 'workflows-onboarding_step1';
}

export class WorkflowsOnboardingStep2 implements BaseEvent {
  event_type = 'workflows-onboarding_step2';

  constructor(public event_properties: WorkflowsOnboardingStep2Properties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsOperatorExampleHover implements BaseEvent {
  event_type = 'workflows-operator_example_hover';

  constructor(public event_properties: WorkflowsOperatorExampleHoverProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsOperatorNodeClicked implements BaseEvent {
  event_type = 'workflows-operator_node_clicked';

  constructor(public event_properties: WorkflowsOperatorNodeClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsOperatorNodesListSearch implements BaseEvent {
  event_type = 'workflows-operator_nodes_list_search';

  constructor(public event_properties: WorkflowsOperatorNodesListSearchProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsOperatorNodesListTabClicked implements BaseEvent {
  event_type = 'workflows-operator_nodes_list_tab_clicked';

  constructor(public event_properties: WorkflowsOperatorNodesListTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsPublishWorkflowClicked implements BaseEvent {
  event_type = 'workflows-publish_workflow_clicked';

  constructor(public event_properties: WorkflowsPublishWorkflowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunPublishedWorkflowClicked implements BaseEvent {
  event_type = 'workflows-run_published_workflow_clicked';

  constructor(public event_properties: WorkflowsRunPublishedWorkflowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsNodeLogsModalClosed implements BaseEvent {
  event_type = 'workflows-runs_node_logs_modal_closed';

  constructor(public event_properties: WorkflowsRunsNodeLogsModalClosedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsNodeLogsModalOpened implements BaseEvent {
  event_type = 'workflows-runs_node_logs_modal_opened';

  constructor(public event_properties: WorkflowsRunsNodeLogsModalOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsNodeLogsTabClicked implements BaseEvent {
  event_type = 'workflows-runs_node_logs_tab_clicked';

  constructor(public event_properties: WorkflowsRunsNodeLogsTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsNodePreviewTabClicked implements BaseEvent {
  event_type = 'workflows-runs_node_preview_tab_clicked';

  constructor(public event_properties: WorkflowsRunsNodePreviewTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsOperatorViewConfigClicked implements BaseEvent {
  event_type = 'workflows-runs_operator_view_config_clicked';

  constructor(public event_properties: WorkflowsRunsOperatorViewConfigClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsRunClicked implements BaseEvent {
  event_type = 'workflows-runs_run_clicked';

  constructor(public event_properties: WorkflowsRunsRunClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsRunFlowClicked implements BaseEvent {
  event_type = 'workflows-runs_run_flow_clicked';

  constructor(public event_properties: WorkflowsRunsRunFlowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsRunModalClosed implements BaseEvent {
  event_type = 'workflows-runs_run_modal_closed';

  constructor(public event_properties: WorkflowsRunsRunModalClosedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsRunNumberCardClicked implements BaseEvent {
  event_type = 'workflows-runs_run-number-card-clicked';

  constructor(public event_properties: WorkflowsRunsRunNumberCardClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsRunsHistoryTabClicked implements BaseEvent {
  event_type = 'workflows-runs_runs_history_tab_clicked';

  constructor(public event_properties: WorkflowsRunsRunsHistoryTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsRunsTabitemClicked implements BaseEvent {
  event_type = 'workflows-runs_runs_tabitem_clicked';

  constructor(public event_properties: WorkflowsRunsRunsTabitemClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsScheduleButtonClicked implements BaseEvent {
  event_type = 'workflows-runs_schedule_button_clicked';

  constructor(public event_properties: WorkflowsRunsScheduleButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsScheduleModalClosed implements BaseEvent {
  event_type = 'workflows-runs_schedule_modal_closed';

  constructor(public event_properties: WorkflowsRunsScheduleModalClosedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsScheduleModalOpened implements BaseEvent {
  event_type = 'workflows-runs_schedule_modal_opened';

  constructor(public event_properties: WorkflowsRunsScheduleModalOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsRunsUpcomingTabitemClicked implements BaseEvent {
  event_type = 'workflows-runs_upcoming_tabitem_clicked';

  constructor(public event_properties: WorkflowsRunsUpcomingTabitemClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class WorkflowsViewLastDebugRunClicked implements BaseEvent {
  event_type = 'workflows-view_last_debug_run_clicked';

  constructor(public event_properties: WorkflowsViewLastDebugRunClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PublicDataAnalyticsSuggestedQuestionClicked implements BaseEvent {
  event_type = 'public-data-analytics_suggested_question_clicked';

  constructor(public event_properties: PublicDataAnalyticsSuggestedQuestionClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PublicDataAnalyticsModalBannerSignUpClicked implements BaseEvent {
  event_type = 'public-data-analytics_modal_banner_sign_up_clicked';

  constructor(public event_properties: PublicDataAnalyticsModalBannerSignUpClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PublicDataAnalyticsHeaderBannerSignUpClicked implements BaseEvent {
  event_type = 'public-data-analytics_header_banner_sign_up_clicked';

  constructor(public event_properties: PublicDataAnalyticsHeaderBannerSignUpClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PublicDataAnalyticsHeaderSignUpClicked implements BaseEvent {
  event_type = 'public-data-analytics_header_sign_up_clicked';

  constructor(public event_properties: PublicDataAnalyticsHeaderSignUpClickedProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * app_loaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/app_loaded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. user)
   * @param options Amplitude event options.
   */
  appLoaded(
    properties?: AppLoadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppLoaded(properties), options);
  }

  /**
   * Copy of workflows-runs_run-number-logs-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/Copy%20of%20workflows-runs_run-number-logs-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. runId)
   * @param options Amplitude event options.
   */
  copyOfWorkflowsRunsRunNumberLogsClicked(
    properties: CopyOfWorkflowsRunsRunNumberLogsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyOfWorkflowsRunsRunNumberLogsClicked(properties), options);
  }

  /**
   * copyedit-add_dictionary_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-add_dictionary_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditAddDictionaryClicked(
    properties: CopyeditAddDictionaryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditAddDictionaryClicked(properties), options);
  }

  /**
   * copyedit-add_dictionary_word_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-add_dictionary_word_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditAddDictionaryWordClicked(
    properties: CopyeditAddDictionaryWordClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditAddDictionaryWordClicked(properties), options);
  }

  /**
   * copyedit-add_document
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-add_document)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditAddDocument(
    properties: CopyeditAddDocumentProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditAddDocument(properties), options);
  }

  /**
   * copyedit-all_projects_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-all_projects_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditAllProjectsClicked(
    properties: CopyeditAllProjectsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditAllProjectsClicked(properties), options);
  }

  /**
   * copyedit-analytics_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-analytics_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditAnalyticsClicked(
    properties: CopyeditAnalyticsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditAnalyticsClicked(properties), options);
  }

  /**
   * copyedit-custom_dictionary_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-custom_dictionary_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditCustomDictionaryTabClicked(
    properties: CopyeditCustomDictionaryTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditCustomDictionaryTabClicked(properties), options);
  }

  /**
   * copyedit-custom_rules_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-custom_rules_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditCustomRulesTabClicked(
    properties: CopyeditCustomRulesTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditCustomRulesTabClicked(properties), options);
  }

  /**
   * copyedit-delete_dictionary_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-delete_dictionary_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditDeleteDictionaryClicked(
    properties: CopyeditDeleteDictionaryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDeleteDictionaryClicked(properties), options);
  }

  /**
   * copyedit-delete_dictionary_word_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-delete_dictionary_word_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditDeleteDictionaryWordClicked(
    properties: CopyeditDeleteDictionaryWordClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDeleteDictionaryWordClicked(properties), options);
  }

  /**
   * copyedit-dictionary_add_cancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-dictionary_add_cancelled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditDictionaryAddCancelled(
    properties: CopyeditDictionaryAddCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDictionaryAddCancelled(properties), options);
  }

  /**
   * copyedit-dictionary_added
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-dictionary_added)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditDictionaryAdded(
    properties: CopyeditDictionaryAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDictionaryAdded(properties), options);
  }

  /**
   * copyedit-doc_ai_content_detection_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_ai_content_detection_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocAiContentDetectionClicked(
    properties: CopyeditDocAiContentDetectionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocAiContentDetectionClicked(properties), options);
  }

  /**
   * copyedit-doc_check_ai_content_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_check_ai_content_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocCheckAiContentClicked(
    properties: CopyeditDocCheckAiContentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocCheckAiContentClicked(properties), options);
  }

  /**
   * copyedit-doc_check_plagiarism_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_check_plagiarism_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocCheckPlagiarismClicked(
    properties: CopyeditDocCheckPlagiarismClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocCheckPlagiarismClicked(properties), options);
  }

  /**
   * copyedit-doc_export_original_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_export_original_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocExportOriginalClicked(
    properties: CopyeditDocExportOriginalClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocExportOriginalClicked(properties), options);
  }

  /**
   * copyedit-doc_export_revised_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_export_revised_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocExportRevisedClicked(
    properties: CopyeditDocExportRevisedClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocExportRevisedClicked(properties), options);
  }

  /**
   * copyedit-doc_history_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_history_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocHistoryClicked(
    properties: CopyeditDocHistoryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocHistoryClicked(properties), options);
  }

  /**
   * copyedit-doc_improvements_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_improvements_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocImprovementsTabClicked(
    properties: CopyeditDocImprovementsTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocImprovementsTabClicked(properties), options);
  }

  /**
   * copyedit-doc_page_number_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_page_number_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocPageNumberClicked(
    properties: CopyeditDocPageNumberClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocPageNumberClicked(properties), options);
  }

  /**
   * copyedit-doc_plagiarism_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_plagiarism_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocPlagiarismTabClicked(
    properties: CopyeditDocPlagiarismTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocPlagiarismTabClicked(properties), options);
  }

  /**
   * copyedit-doc_readbility_wordcount_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_readbility_wordcount_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocReadbilityWordcountClicked(
    properties: CopyeditDocReadbilityWordcountClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocReadbilityWordcountClicked(properties), options);
  }

  /**
   * copyedit-doc_readbility_wordcount_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_readbility_wordcount_closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocReadbilityWordcountClosed(
    properties: CopyeditDocReadbilityWordcountClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocReadbilityWordcountClosed(properties), options);
  }

  /**
   * copyedit-doc_style_guides_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_style_guides_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocStyleGuidesTabClicked(
    properties: CopyeditDocStyleGuidesTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocStyleGuidesTabClicked(properties), options);
  }

  /**
   * copyedit-doc_suggestion_accept_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_suggestion_accept_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocSuggestionAcceptClicked(
    properties: CopyeditDocSuggestionAcceptClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocSuggestionAcceptClicked(properties), options);
  }

  /**
   * copyedit-doc_suggestion_dismiss_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_suggestion_dismiss_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocSuggestionDismissClicked(
    properties: CopyeditDocSuggestionDismissClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocSuggestionDismissClicked(properties), options);
  }

  /**
   * copyedit-doc_version_number_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-doc_version_number_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocVersionNumberClicked(
    properties: CopyeditDocVersionNumberClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocVersionNumberClicked(properties), options);
  }

  /**
   * copyedit-document_created
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-document_created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditDocumentCreated(
    properties: CopyeditDocumentCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocumentCreated(properties), options);
  }

  /**
   * copyedit-document_creation_cancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-document_creation_cancelled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditDocumentCreationCancelled(
    properties: CopyeditDocumentCreationCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocumentCreationCancelled(properties), options);
  }

  /**
   * copyedit-document_delete_cancel
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-document_delete_cancel)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocumentDeleteCancel(
    properties: CopyeditDocumentDeleteCancelProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocumentDeleteCancel(properties), options);
  }

  /**
   * copyedit-document_deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-document_deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocumentDeleted(
    properties: CopyeditDocumentDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocumentDeleted(properties), options);
  }

  /**
   * copyedit-document_invite_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-document_invite_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditDocumentInviteClicked(
    properties: CopyeditDocumentInviteClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocumentInviteClicked(properties), options);
  }

  /**
   * copyedit-document_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-document_opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. documentId)
   * @param options Amplitude event options.
   */
  copyeditDocumentOpened(
    properties: CopyeditDocumentOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditDocumentOpened(properties), options);
  }

  /**
   * copyedit-edit_dictionary_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-edit_dictionary_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditEditDictionaryClicked(
    properties: CopyeditEditDictionaryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditEditDictionaryClicked(properties), options);
  }

  /**
   * copyedit-invited_members_list_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-invited_members_list_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditInvitedMembersListClicked(
    properties: CopyeditInvitedMembersListClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditInvitedMembersListClicked(properties), options);
  }

  /**
   * copyedit-launch_app
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-launch_app)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditLaunchApp(
    properties: CopyeditLaunchAppProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditLaunchApp(properties), options);
  }

  /**
   * copyedit-project_cancel
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-project_cancel)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditProjectCancel(
    properties: CopyeditProjectCancelProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditProjectCancel(properties), options);
  }

  /**
   * copyedit-project_click
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-project_click)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditProjectClick(
    properties: CopyeditProjectClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditProjectClick(properties), options);
  }

  /**
   * copyedit-project_create
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-project_create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditProjectCreate(
    properties: CopyeditProjectCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditProjectCreate(properties), options);
  }

  /**
   * copyedit-projects_back_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-projects_back_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditProjectsBackButtonClicked(
    properties: CopyeditProjectsBackButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditProjectsBackButtonClicked(properties), options);
  }

  /**
   * copyedit-remove_invite_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-remove_invite_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditRemoveInviteClicked(
    properties: CopyeditRemoveInviteClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditRemoveInviteClicked(properties), options);
  }

  /**
   * copyedit-settings_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-settings_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  copyeditSettingsClicked(
    properties: CopyeditSettingsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditSettingsClicked(properties), options);
  }

  /**
   * copyedit-share_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/copyedit-share_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  copyeditShareButtonClicked(
    properties: CopyeditShareButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyeditShareButtonClicked(properties), options);
  }

  /**
   * dashboard_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dashboard_viewed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. user)
   * @param options Amplitude event options.
   */
  dashboardViewed(
    properties: DashboardViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardViewed(properties), options);
  }

  /**
   * dataset_analysis-trigger_analyzers
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset_analysis-trigger_analyzers)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasetId)
   * @param options Amplitude event options.
   */
  datasetAnalysisTriggerAnalyzers(
    properties: DatasetAnalysisTriggerAnalyzersProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetAnalysisTriggerAnalyzers(properties), options);
  }

  /**
   * dataset_details_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset_details_viewed)
   *
   * Owner: PANKAJ RAJAN
   *
   * @param options Amplitude event options.
   */
  datasetDetailsViewed(
    options?: EventOptions,
  ) {
    return this.track(new DatasetDetailsViewed(), options);
  }

  /**
   * dataset_register_step1
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset_register_step1)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStep1(
    properties: DatasetRegisterStep1Properties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStep1(properties), options);
  }

  /**
   * dataset_register_step2
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset_register_step2)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStep2(
    properties: DatasetRegisterStep2Properties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStep2(properties), options);
  }

  /**
   * dataset_register_step3
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset_register_step3)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStep3(
    properties: DatasetRegisterStep3Properties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStep3(properties), options);
  }

  /**
   * dataset_subset_add_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset_subset_add_clicked)
   *
   * Used in Embeddings
   *
   * Owner: Kris Heinrich
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetSubsetAddClicked(
    properties: DatasetSubsetAddClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetSubsetAddClicked(properties), options);
  }

  /**
   * dataset-register-cancel
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-cancel)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterCancel(
    properties: DatasetRegisterCancelProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterCancel(properties), options);
  }

  /**
   * dataset-register-step-confirm_columns-back
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-confirm_columns-back)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepConfirmColumnsBack(
    properties: DatasetRegisterStepConfirmColumnsBackProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepConfirmColumnsBack(properties), options);
  }

  /**
   * dataset-register-step-confirm_columns-next
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-confirm_columns-next)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepConfirmColumnsNext(
    properties: DatasetRegisterStepConfirmColumnsNextProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepConfirmColumnsNext(properties), options);
  }

  /**
   * dataset-register-step-details_form-back
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-details_form-back)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepDetailsFormBack(
    properties: DatasetRegisterStepDetailsFormBackProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepDetailsFormBack(properties), options);
  }

  /**
   * dataset-register-step-details_form-next
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-details_form-next)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepDetailsFormNext(
    properties: DatasetRegisterStepDetailsFormNextProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepDetailsFormNext(properties), options);
  }

  /**
   * dataset-register-step-import_dataset
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-import_dataset)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepImportDataset(
    properties: DatasetRegisterStepImportDatasetProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepImportDataset(properties), options);
  }

  /**
   * dataset-register-step-select_analyzers-back
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-select_analyzers-back)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepSelectAnalyzersBack(
    properties: DatasetRegisterStepSelectAnalyzersBackProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepSelectAnalyzersBack(properties), options);
  }

  /**
   * dataset-register-step-select_analyzers-next
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-select_analyzers-next)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepSelectAnalyzersNext(
    properties: DatasetRegisterStepSelectAnalyzersNextProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepSelectAnalyzersNext(properties), options);
  }

  /**
   * dataset-register-step-select_analyzers-save_selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-select_analyzers-save_selection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepSelectAnalyzersSaveSelection(
    properties: DatasetRegisterStepSelectAnalyzersSaveSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepSelectAnalyzersSaveSelection(properties), options);
  }

  /**
   * dataset-register-step-simple_upload
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/dataset-register-step-simple_upload)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetRegisterStepSimpleUpload(
    properties: DatasetRegisterStepSimpleUploadProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetRegisterStepSimpleUpload(properties), options);
  }

  /**
   * datasets_add_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_add_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. sourcePage)
   * @param options Amplitude event options.
   */
  datasetsAddClicked(
    properties: DatasetsAddClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsAddClicked(properties), options);
  }

  /**
   * datasets_analysis_completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_analysis_completed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. analysis_completion_failed)
   * @param options Amplitude event options.
   */
  datasetsAnalysisCompleted(
    properties: DatasetsAnalysisCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsAnalysisCompleted(properties), options);
  }

  /**
   * datasets_analysis_downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_analysis_downloaded)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. analysis_type_id)
   * @param options Amplitude event options.
   */
  datasetsAnalysisDownloaded(
    properties: DatasetsAnalysisDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsAnalysisDownloaded(properties), options);
  }

  /**
   * datasets_basicanalysis_selectanalysis_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_basicanalysis_selectanalysis_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. analysis_type_id)
   * @param options Amplitude event options.
   */
  datasetsBasicanalysisSelectanalysisClicked(
    properties: DatasetsBasicanalysisSelectanalysisClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsBasicanalysisSelectanalysisClicked(properties), options);
  }

  /**
   * datasets_cancel_registration
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_cancel_registration)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  datasetsCancelRegistration(
    properties: DatasetsCancelRegistrationProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsCancelRegistration(properties), options);
  }

  /**
   * datasets_compared
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_compared)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  datasetsCompared(
    properties: DatasetsComparedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsCompared(properties), options);
  }

  /**
   * datasets_details_apps_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_details_apps_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsDetailsAppsTabClicked(
    properties: DatasetsDetailsAppsTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsDetailsAppsTabClicked(properties), options);
  }

  /**
   * datasets_details_basicanalysis_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_details_basicanalysis_tab_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsDetailsBasicanalysisTabClicked(
    properties: DatasetsDetailsBasicanalysisTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsDetailsBasicanalysisTabClicked(properties), options);
  }

  /**
   * datasets_details_dataprofile_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_details_dataprofile_tab_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsDetailsDataprofileTabClicked(
    properties: DatasetsDetailsDataprofileTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsDetailsDataprofileTabClicked(properties), options);
  }

  /**
   * datasets_details_embeddings_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_details_embeddings_tab_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsDetailsEmbeddingsTabClicked(
    properties: DatasetsDetailsEmbeddingsTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsDetailsEmbeddingsTabClicked(properties), options);
  }

  /**
   * datasets_details_fullanalysis_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_details_fullanalysis_tab_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsDetailsFullanalysisTabClicked(
    properties: DatasetsDetailsFullanalysisTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsDetailsFullanalysisTabClicked(properties), options);
  }

  /**
   * datasets_details_relabeling_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_details_relabeling_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsDetailsRelabelingTabClicked(
    properties: DatasetsDetailsRelabelingTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsDetailsRelabelingTabClicked(properties), options);
  }

  /**
   * datasets_details_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_details_tab_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsDetailsTabClicked(
    properties: DatasetsDetailsTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsDetailsTabClicked(properties), options);
  }

  /**
   * datasets_details_workflows_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_details_workflows_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsDetailsWorkflowsTabClicked(
    properties: DatasetsDetailsWorkflowsTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsDetailsWorkflowsTabClicked(properties), options);
  }

  /**
   * datasets_fullanalysis_newanalysis_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_fullanalysis_newanalysis_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsFullanalysisNewanalysisClicked(
    properties: DatasetsFullanalysisNewanalysisClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsFullanalysisNewanalysisClicked(properties), options);
  }

  /**
   * datasets_fullanalysis_newanalysis_completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_fullanalysis_newanalysis_completed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsFullanalysisNewanalysisCompleted(
    properties: DatasetsFullanalysisNewanalysisCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsFullanalysisNewanalysisCompleted(properties), options);
  }

  /**
   * datasets_relabeling_generate_new_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_relabeling_generate_new_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasetId)
   * @param options Amplitude event options.
   */
  datasetsRelabelingGenerateNewClicked(
    properties: DatasetsRelabelingGenerateNewClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsRelabelingGenerateNewClicked(properties), options);
  }

  /**
   * datasets_relabeling_generate_new_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_relabeling_generate_new_closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasetId)
   * @param options Amplitude event options.
   */
  datasetsRelabelingGenerateNewClosed(
    properties: DatasetsRelabelingGenerateNewClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsRelabelingGenerateNewClosed(properties), options);
  }

  /**
   * datasets_relabeling_prepare_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_relabeling_prepare_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasetId)
   * @param options Amplitude event options.
   */
  datasetsRelabelingPrepareClicked(
    properties: DatasetsRelabelingPrepareClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsRelabelingPrepareClicked(properties), options);
  }

  /**
   * datasets_tasks_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets_tasks_viewed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  datasetsTasksViewed(
    properties: DatasetsTasksViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsTasksViewed(properties), options);
  }

  /**
   * datasets-list-item-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets-list-item-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. datasetId)
   * @param options Amplitude event options.
   */
  datasetsListItemClicked(
    properties: DatasetsListItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsListItemClicked(properties), options);
  }

  /**
   * datasets-list:viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets-list%3Aviewed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  datasetsListViewed(
    properties: DatasetsListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsListViewed(properties), options);
  }

  /**
   * datasets-view-sample
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/datasets-view-sample)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  datasetsViewSample(
    properties?: DatasetsViewSampleProperties,
    options?: EventOptions,
  ) {
    return this.track(new DatasetsViewSample(properties), options);
  }

  /**
   * docs_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/docs_button_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  docsButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new DocsButtonClicked(), options);
  }

  /**
   * evaluation-list-item-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/evaluation-list-item-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. evaluationId)
   * @param options Amplitude event options.
   */
  evaluationListItemClicked(
    properties: EvaluationListItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EvaluationListItemClicked(properties), options);
  }

  /**
   * evaluations_added
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/evaluations_added)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. evaluations_id)
   * @param options Amplitude event options.
   */
  evaluationsAdded(
    properties: EvaluationsAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EvaluationsAdded(properties), options);
  }

  /**
   * evaluations_compared
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/evaluations_compared)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. evaluations_id)
   * @param options Amplitude event options.
   */
  evaluationsCompared(
    properties: EvaluationsComparedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EvaluationsCompared(properties), options);
  }

  /**
   * evaluations_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/evaluations_viewed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. evaluations_id)
   * @param options Amplitude event options.
   */
  evaluationsViewed(
    properties: EvaluationsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EvaluationsViewed(properties), options);
  }

  /**
   * evaluations-view-sample
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/evaluations-view-sample)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  evaluationsViewSample(
    properties: EvaluationsViewSampleProperties,
    options?: EventOptions,
  ) {
    return this.track(new EvaluationsViewSample(properties), options);
  }

  /**
   * experiment-list-item-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/experiment-list-item-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experimentId)
   * @param options Amplitude event options.
   */
  experimentListItemClicked(
    properties: ExperimentListItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentListItemClicked(properties), options);
  }

  /**
   * experiments_added
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/experiments_added)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. experiments_id)
   * @param options Amplitude event options.
   */
  experimentsAdded(
    properties: ExperimentsAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentsAdded(properties), options);
  }

  /**
   * experiments_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/experiments_viewed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. experiments_id)
   * @param options Amplitude event options.
   */
  experimentsViewed(
    properties: ExperimentsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentsViewed(properties), options);
  }

  /**
   * experiments-view-sample
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/experiments-view-sample)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  experimentsViewSample(
    properties: ExperimentsViewSampleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentsViewSample(properties), options);
  }

  /**
   * go-to-datasets-list
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/go-to-datasets-list)
   *
   * This event tracks when users navigate to the datasets section of the platform
   *
   * @param properties The event's properties (e.g. pageSource)
   * @param options Amplitude event options.
   */
  goToDatasetsList(
    properties: GoToDatasetsListProperties,
    options?: EventOptions,
  ) {
    return this.track(new GoToDatasetsList(properties), options);
  }

  /**
   * invite-send-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/invite-send-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. emailsForInvite)
   * @param options Amplitude event options.
   */
  inviteSendClicked(
    properties: InviteSendClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InviteSendClicked(properties), options);
  }

  /**
   * Land on Dataset Registration
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/Land%20on%20Dataset%20Registration)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. datasetReg)
   * @param options Amplitude event options.
   */
  landOnDatasetRegistration(
    properties: LandOnDatasetRegistrationProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandOnDatasetRegistration(properties), options);
  }

  /**
   * members_added
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/members_added)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  membersAdded(
    properties: MembersAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MembersAdded(properties), options);
  }

  /**
   * model_app_create
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/model_app_create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pageSource)
   * @param options Amplitude event options.
   */
  modelAppCreate(
    properties: ModelAppCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModelAppCreate(properties), options);
  }

  /**
   * model_app_infer
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/model_app_infer)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. modelAppId)
   * @param options Amplitude event options.
   */
  modelAppInfer(
    properties: ModelAppInferProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModelAppInfer(properties), options);
  }

  /**
   * model_app_infer_options
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/model_app_infer_options)
   *
   * This event tracks the click when a user clicks on 3 dots after inference is available
   *
   * @param properties The event's properties (e.g. modelAppId)
   * @param options Amplitude event options.
   */
  modelAppInferOptions(
    properties: ModelAppInferOptionsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModelAppInferOptions(properties), options);
  }

  /**
   * model_app_open
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/model_app_open)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. modelAppId)
   * @param options Amplitude event options.
   */
  modelAppOpen(
    properties: ModelAppOpenProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModelAppOpen(properties), options);
  }

  /**
   * model_app_sidebar_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/model_app_sidebar_clicked)
   *
   * This event tracks when a user clicks on the sidebar in the Model Apps page. Covers both the info button and the share button
   *
   * @param properties The event's properties (e.g. modelAppId)
   * @param options Amplitude event options.
   */
  modelAppSidebarClicked(
    properties: ModelAppSidebarClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModelAppSidebarClicked(properties), options);
  }

  /**
   * model-app-view-sample
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/model-app-view-sample)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  modelAppViewSample(
    properties: ModelAppViewSampleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModelAppViewSample(properties), options);
  }

  /**
   * navbar_dashboard_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_dashboard_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarDashboardClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarDashboardClicked(), options);
  }

  /**
   * navbar_datasets_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_datasets_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarDatasetsClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarDatasetsClicked(), options);
  }

  /**
   * navbar_evaluation_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_evaluation_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarEvaluationClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarEvaluationClicked(), options);
  }

  /**
   * navbar_experiment_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_experiment_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarExperimentClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarExperimentClicked(), options);
  }

  /**
   * navbar_invite_members_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_invite_members_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  navbarInviteMembersClicked(
    properties: NavbarInviteMembersClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarInviteMembersClicked(properties), options);
  }

  /**
   * navbar_menu_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_menu_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarMenuClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarMenuClicked(), options);
  }

  /**
   * navbar_model_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_model_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarModelClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarModelClicked(), options);
  }

  /**
   * navbar_notebook_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_notebook_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarNotebookClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarNotebookClicked(), options);
  }

  /**
   * navbar_projects_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_projects_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarProjectsClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarProjectsClicked(), options);
  }

  /**
   * navbar_runs_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_runs_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarRunsClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarRunsClicked(), options);
  }

  /**
   * navbar_settings_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_settings_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarSettingsClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarSettingsClicked(), options);
  }

  /**
   * navbar_snippets_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_snippets_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  navbarSnippetsClicked(
    options?: EventOptions,
  ) {
    return this.track(new NavbarSnippetsClicked(), options);
  }

  /**
   * navbar_whatsnew_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/navbar_whatsnew_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  navbarWhatsnewClicked(
    properties: NavbarWhatsnewClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarWhatsnewClicked(properties), options);
  }

  /**
   * Onboarding step 1
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/Onboarding%20step%201)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  onboardingStep1(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStep1(), options);
  }

  /**
   * Onboarding step 2
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/Onboarding%20step%202)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. user)
   * @param options Amplitude event options.
   */
  onboardingStep2(
    properties?: OnboardingStep2Properties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStep2(properties), options);
  }

  /**
   * Onboarding step 3
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/Onboarding%20step%203)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. user)
   * @param options Amplitude event options.
   */
  onboardingStep3(
    properties: OnboardingStep3Properties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStep3(properties), options);
  }

  /**
   * payment-manage_subscription_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/payment-manage_subscription_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  paymentManageSubscriptionClicked(
    properties: PaymentManageSubscriptionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentManageSubscriptionClicked(properties), options);
  }

  /**
   * payment-plan_type_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/payment-plan_type_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. planType)
   * @param options Amplitude event options.
   */
  paymentPlanTypeClicked(
    properties: PaymentPlanTypeClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentPlanTypeClicked(properties), options);
  }

  /**
   * payment-view_plan_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/payment-view_plan_clicked)
   *
   * Owner: Akshat Akshat
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  paymentViewPlanClicked(
    properties: PaymentViewPlanClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentViewPlanClicked(properties), options);
  }

  /**
   * project-list-item-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/project-list-item-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. isDemoArtifact)
   * @param options Amplitude event options.
   */
  projectListItemClicked(
    properties: ProjectListItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectListItemClicked(properties), options);
  }

  /**
   * projects_add_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/projects_add_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. pageSource)
   * @param options Amplitude event options.
   */
  projectsAddClicked(
    properties: ProjectsAddClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectsAddClicked(properties), options);
  }

  /**
   * projects_added
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/projects_added)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. datasets_id)
   * @param options Amplitude event options.
   */
  projectsAdded(
    properties: ProjectsAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectsAdded(properties), options);
  }

  /**
   * projects_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/projects_viewed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. user)
   * @param options Amplitude event options.
   */
  projectsViewed(
    properties: ProjectsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectsViewed(properties), options);
  }

  /**
   * projects-view-sample
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/projects-view-sample)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  projectsViewSample(
    properties: ProjectsViewSampleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectsViewSample(properties), options);
  }

  /**
   * sdk_setup_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/sdk_setup_clicked)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. sdk_already_setup)
   * @param options Amplitude event options.
   */
  sdkSetupClicked(
    properties: SdkSetupClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SdkSetupClicked(properties), options);
  }

  /**
   * sdk_setup_completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/sdk_setup_completed)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  sdkSetupCompleted(
    properties: SdkSetupCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SdkSetupCompleted(properties), options);
  }

  /**
   * search_app-code_btn_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/search_app-code_btn_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  searchAppCodeBtnClicked(
    properties: SearchAppCodeBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchAppCodeBtnClicked(properties), options);
  }

  /**
   * search_app-delete_thread_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/search_app-delete_thread_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  searchAppDeleteThreadClicked(
    properties: SearchAppDeleteThreadClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchAppDeleteThreadClicked(properties), options);
  }

  /**
   * search_app-description_updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/search_app-description_updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  searchAppDescriptionUpdated(
    properties: SearchAppDescriptionUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchAppDescriptionUpdated(properties), options);
  }

  /**
   * search_app-name_updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/search_app-name_updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  searchAppNameUpdated(
    properties: SearchAppNameUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchAppNameUpdated(properties), options);
  }

  /**
   * search_app-new_thread_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/search_app-new_thread_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  searchAppNewThreadClicked(
    properties: SearchAppNewThreadClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchAppNewThreadClicked(properties), options);
  }

  /**
   * search_app-send_query
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/search_app-send_query)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  searchAppSendQuery(
    properties: SearchAppSendQueryProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchAppSendQuery(properties), options);
  }

  /**
   * search_app-settings_btn_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/search_app-settings_btn_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  searchAppSettingsBtnClicked(
    properties: SearchAppSettingsBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchAppSettingsBtnClicked(properties), options);
  }

  /**
   * search_app-thread_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/search_app-thread_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  searchAppThreadClicked(
    properties: SearchAppThreadClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchAppThreadClicked(properties), options);
  }

  /**
   * snippet_add_comment
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet_add_comment)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. snippetCommand)
   * @param options Amplitude event options.
   */
  snippetAddComment(
    properties: SnippetAddCommentProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetAddComment(properties), options);
  }

  /**
   * snippet_add_item
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet_add_item)
   *
   * This is used when the user clicks on "Add item" in the side panel in snippets
   *
   * @param properties The event's properties (e.g. snippetId)
   * @param options Amplitude event options.
   */
  snippetAddItem(
    properties: SnippetAddItemProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetAddItem(properties), options);
  }

  /**
   * snippet_add_slash_command
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet_add_slash_command)
   *
   * This event tracks when a user adds a slash command to their snippets
   *
   * @param properties The event's properties (e.g. snippetCommand)
   * @param options Amplitude event options.
   */
  snippetAddSlashCommand(
    properties: SnippetAddSlashCommandProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetAddSlashCommand(properties), options);
  }

  /**
   * snippet_change_mode
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet_change_mode)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. snippetChangeMode)
   * @param options Amplitude event options.
   */
  snippetChangeMode(
    properties: SnippetChangeModeProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetChangeMode(properties), options);
  }

  /**
   * snippet_delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet_delete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pageSource)
   * @param options Amplitude event options.
   */
  snippetDelete(
    properties: SnippetDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetDelete(properties), options);
  }

  /**
   * snippet_share_button
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet_share_button)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. shareSnippetAcrossWorkspace)
   * @param options Amplitude event options.
   */
  snippetShareButton(
    properties: SnippetShareButtonProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetShareButton(properties), options);
  }

  /**
   * snippet_view_all
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet_view_all)
   *
   * This event tracks when a user views all snippets available on the platform
   *
   * @param properties The event's properties (e.g. pageSource)
   * @param options Amplitude event options.
   */
  snippetViewAll(
    properties: SnippetViewAllProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetViewAll(properties), options);
  }

  /**
   * snippet-add-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet-add-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pageSource)
   * @param options Amplitude event options.
   */
  snippetAddClicked(
    properties: SnippetAddClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetAddClicked(properties), options);
  }

  /**
   * snippet-item-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet-item-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. isDemoArtifact)
   * @param options Amplitude event options.
   */
  snippetItemClicked(
    properties: SnippetItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetItemClicked(properties), options);
  }

  /**
   * snippet-view-sample
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/snippet-view-sample)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  snippetViewSample(
    properties: SnippetViewSampleProperties,
    options?: EventOptions,
  ) {
    return this.track(new SnippetViewSample(properties), options);
  }

  /**
   * switch_workspace_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/switch_workspace_clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  switchWorkspaceClicked(
    options?: EventOptions,
  ) {
    return this.track(new SwitchWorkspaceClicked(), options);
  }

  /**
   * User Sign in
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/User%20Sign%20in)
   *
   * Owner: Piyush Bhopalka
   *
   * @param properties The event's properties (e.g. user)
   * @param options Amplitude event options.
   */
  userSignIn(
    properties: UserSignInProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserSignIn(properties), options);
  }

  /**
   * User Sign-up
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/User%20Sign-up)
   *
   * Owner: ashish.tayal@markovml.com
   *
   * @param properties The event's properties (e.g. region)
   * @param options Amplitude event options.
   */
  userSignUp(
    properties: UserSignUpProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserSignUp(properties), options);
  }

  /**
   * welcome_modal-primary-cta-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/welcome_modal-primary-cta-clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  welcomeModalPrimaryCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new WelcomeModalPrimaryCtaClicked(), options);
  }

  /**
   * welcome-modal-close-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/welcome-modal-close-clicked)
   *
   * Owner: Piyush Bhopalka
   *
   * @param options Amplitude event options.
   */
  welcomeModalCloseClicked(
    options?: EventOptions,
  ) {
    return this.track(new WelcomeModalCloseClicked(), options);
  }

  /**
   * workflow-apps_deploy_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflow-apps_deploy_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowAppsDeployButtonClicked(
    properties: WorkflowAppsDeployButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowAppsDeployButtonClicked(properties), options);
  }

  /**
   * workflow-apps_live_app_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflow-apps_live_app_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowAppId)
   * @param options Amplitude event options.
   */
  workflowAppsLiveAppButtonClicked(
    properties: WorkflowAppsLiveAppButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowAppsLiveAppButtonClicked(properties), options);
  }

  /**
   * workflows-add_operator_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-add_operator_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. operatorId)
   * @param options Amplitude event options.
   */
  workflowsAddOperatorClicked(
    properties: WorkflowsAddOperatorClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAddOperatorClicked(properties), options);
  }

  /**
   * workflows-add_operator_dropped
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-add_operator_dropped)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. operatorId)
   * @param options Amplitude event options.
   */
  workflowsAddOperatorDropped(
    properties: WorkflowsAddOperatorDroppedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAddOperatorDropped(properties), options);
  }

  /**
   * workflows-apps_deploy_step1
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-apps_deploy_step1)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsAppsDeployStep1(
    properties: WorkflowsAppsDeployStep1Properties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAppsDeployStep1(properties), options);
  }

  /**
   * workflows-apps_deploy_step2
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-apps_deploy_step2)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsAppsDeployStep2(
    properties: WorkflowsAppsDeployStep2Properties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAppsDeployStep2(properties), options);
  }

  /**
   * workflows-apps_execute_run_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-apps_execute_run_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowAppId)
   * @param options Amplitude event options.
   */
  workflowsAppsExecuteRunButtonClicked(
    properties: WorkflowsAppsExecuteRunButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAppsExecuteRunButtonClicked(properties), options);
  }

  /**
   * workflows-apps_next_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-apps_next_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. step)
   * @param options Amplitude event options.
   */
  workflowsAppsNextButtonClicked(
    properties: WorkflowsAppsNextButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAppsNextButtonClicked(properties), options);
  }

  /**
   * workflows-apps_side_panel_new_run_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-apps_side_panel_new_run_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowAppId)
   * @param options Amplitude event options.
   */
  workflowsAppsSidePanelNewRunClicked(
    properties: WorkflowsAppsSidePanelNewRunClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAppsSidePanelNewRunClicked(properties), options);
  }

  /**
   * workflows-apps_side_panel_runs_history_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-apps_side_panel_runs_history_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowAppId)
   * @param options Amplitude event options.
   */
  workflowsAppsSidePanelRunsHistoryClicked(
    properties: WorkflowsAppsSidePanelRunsHistoryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAppsSidePanelRunsHistoryClicked(properties), options);
  }

  /**
   * workflows-apps_stepper-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-apps_stepper-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. step)
   * @param options Amplitude event options.
   */
  workflowsAppsStepperClicked(
    properties: WorkflowsAppsStepperClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsAppsStepperClicked(properties), options);
  }

  /**
   * workflows-clone_workflow_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-clone_workflow_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. sourceWorkflowId)
   * @param options Amplitude event options.
   */
  workflowsCloneWorkflowClicked(
    properties: WorkflowsCloneWorkflowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsCloneWorkflowClicked(properties), options);
  }

  /**
   * workflows-create_blank_workflow_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-create_blank_workflow_clicked)
   *
   * User clicked on big blue button to create a blank workflow from templates page
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  workflowsCreateBlankWorkflowClicked(
    properties: WorkflowsCreateBlankWorkflowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsCreateBlankWorkflowClicked(properties), options);
  }

  /**
   * workflows-create_workflow_from_template_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-create_workflow_from_template_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. templateId)
   * @param options Amplitude event options.
   */
  workflowsCreateWorkflowFromTemplateClicked(
    properties: WorkflowsCreateWorkflowFromTemplateClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsCreateWorkflowFromTemplateClicked(properties), options);
  }

  /**
   * workflows-debug_run_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-debug_run_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsDebugRunClicked(
    properties: WorkflowsDebugRunClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsDebugRunClicked(properties), options);
  }

  /**
   * workflows-free_trial_modal_tour_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-free_trial_modal_tour_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  workflowsFreeTrialModalTourClicked(
    properties: WorkflowsFreeTrialModalTourClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsFreeTrialModalTourClicked(properties), options);
  }

  /**
   * workflows-go_to_all_workflows_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-go_to_all_workflows_clicked)
   *
   * User clicked on go to all workflows on templates list page
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  workflowsGoToAllWorkflowsClicked(
    properties: WorkflowsGoToAllWorkflowsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsGoToAllWorkflowsClicked(properties), options);
  }

  /**
   * workflows-header_section_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-header_section_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  workflowsHeaderSectionButtonClicked(
    properties: WorkflowsHeaderSectionButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsHeaderSectionButtonClicked(properties), options);
  }

  /**
   * workflows-launch_search_app_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-launch_search_app_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsLaunchSearchAppClicked(
    properties: WorkflowsLaunchSearchAppClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsLaunchSearchAppClicked(properties), options);
  }

  /**
   * workflows-link_nodes_with_edge
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-link_nodes_with_edge)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. nodeIds)
   * @param options Amplitude event options.
   */
  workflowsLinkNodesWithEdge(
    properties: WorkflowsLinkNodesWithEdgeProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsLinkNodesWithEdge(properties), options);
  }

  /**
   * workflows-node_delete_btn_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-node_delete_btn_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. nodeId)
   * @param options Amplitude event options.
   */
  workflowsNodeDeleteBtnClicked(
    properties: WorkflowsNodeDeleteBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsNodeDeleteBtnClicked(properties), options);
  }

  /**
   * workflows-node_edit_btn_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-node_edit_btn_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. nodeId)
   * @param options Amplitude event options.
   */
  workflowsNodeEditBtnClicked(
    properties: WorkflowsNodeEditBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsNodeEditBtnClicked(properties), options);
  }

  /**
   * workflows-node_logs_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-node_logs_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. nodeId)
   * @param options Amplitude event options.
   */
  workflowsNodeLogsClicked(
    properties: WorkflowsNodeLogsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsNodeLogsClicked(properties), options);
  }

  /**
   * workflows-node_output_preview_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-node_output_preview_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. nodeId)
   * @param options Amplitude event options.
   */
  workflowsNodeOutputPreviewClicked(
    properties: WorkflowsNodeOutputPreviewClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsNodeOutputPreviewClicked(properties), options);
  }

  /**
   * workflows-onboarding_step1
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-onboarding_step1)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  workflowsOnboardingStep1(
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsOnboardingStep1(), options);
  }

  /**
   * workflows-onboarding_step2
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-onboarding_step2)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. userIntent)
   * @param options Amplitude event options.
   */
  workflowsOnboardingStep2(
    properties: WorkflowsOnboardingStep2Properties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsOnboardingStep2(properties), options);
  }

  /**
   * workflows-operator_example_hover
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-operator_example_hover)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. operatorId)
   * @param options Amplitude event options.
   */
  workflowsOperatorExampleHover(
    properties: WorkflowsOperatorExampleHoverProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsOperatorExampleHover(properties), options);
  }

  /**
   * workflows-operator_node_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-operator_node_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. operatorId)
   * @param options Amplitude event options.
   */
  workflowsOperatorNodeClicked(
    properties: WorkflowsOperatorNodeClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsOperatorNodeClicked(properties), options);
  }

  /**
   * workflows-operator_nodes_list_search
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-operator_nodes_list_search)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. searchQuery)
   * @param options Amplitude event options.
   */
  workflowsOperatorNodesListSearch(
    properties: WorkflowsOperatorNodesListSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsOperatorNodesListSearch(properties), options);
  }

  /**
   * workflows-operator_nodes_list_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-operator_nodes_list_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  workflowsOperatorNodesListTabClicked(
    properties: WorkflowsOperatorNodesListTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsOperatorNodesListTabClicked(properties), options);
  }

  /**
   * workflows-publish_workflow_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-publish_workflow_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. runType)
   * @param options Amplitude event options.
   */
  workflowsPublishWorkflowClicked(
    properties: WorkflowsPublishWorkflowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsPublishWorkflowClicked(properties), options);
  }

  /**
   * workflows-run_published_workflow_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-run_published_workflow_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. runType)
   * @param options Amplitude event options.
   */
  workflowsRunPublishedWorkflowClicked(
    properties: WorkflowsRunPublishedWorkflowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunPublishedWorkflowClicked(properties), options);
  }

  /**
   * workflows-runs_node_logs_modal_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_node_logs_modal_closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. runId)
   * @param options Amplitude event options.
   */
  workflowsRunsNodeLogsModalClosed(
    properties: WorkflowsRunsNodeLogsModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsNodeLogsModalClosed(properties), options);
  }

  /**
   * workflows-runs_node_logs_modal_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_node_logs_modal_opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. runId)
   * @param options Amplitude event options.
   */
  workflowsRunsNodeLogsModalOpened(
    properties: WorkflowsRunsNodeLogsModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsNodeLogsModalOpened(properties), options);
  }

  /**
   * workflows-runs_node_logs_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_node_logs_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. operatorId)
   * @param options Amplitude event options.
   */
  workflowsRunsNodeLogsTabClicked(
    properties: WorkflowsRunsNodeLogsTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsNodeLogsTabClicked(properties), options);
  }

  /**
   * workflows-runs_node_preview_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_node_preview_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. operatorId)
   * @param options Amplitude event options.
   */
  workflowsRunsNodePreviewTabClicked(
    properties: WorkflowsRunsNodePreviewTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsNodePreviewTabClicked(properties), options);
  }

  /**
   * workflows-runs_operator_view_config_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_operator_view_config_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. operatorId)
   * @param options Amplitude event options.
   */
  workflowsRunsOperatorViewConfigClicked(
    properties: WorkflowsRunsOperatorViewConfigClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsOperatorViewConfigClicked(properties), options);
  }

  /**
   * workflows-runs_run_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_run_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsRunClicked(
    properties: WorkflowsRunsRunClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsRunClicked(properties), options);
  }

  /**
   * workflows-runs_run_flow_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_run_flow_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsRunFlowClicked(
    properties: WorkflowsRunsRunFlowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsRunFlowClicked(properties), options);
  }

  /**
   * workflows-runs_run_modal_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_run_modal_closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsRunModalClosed(
    properties: WorkflowsRunsRunModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsRunModalClosed(properties), options);
  }

  /**
   * workflows-runs_run-number-card-clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_run-number-card-clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. runId)
   * @param options Amplitude event options.
   */
  workflowsRunsRunNumberCardClicked(
    properties: WorkflowsRunsRunNumberCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsRunNumberCardClicked(properties), options);
  }

  /**
   * workflows-runs_runs_history_tab_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_runs_history_tab_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsRunsHistoryTabClicked(
    properties: WorkflowsRunsRunsHistoryTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsRunsHistoryTabClicked(properties), options);
  }

  /**
   * workflows-runs_runs_tabitem_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_runs_tabitem_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsRunsTabitemClicked(
    properties: WorkflowsRunsRunsTabitemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsRunsTabitemClicked(properties), options);
  }

  /**
   * workflows-runs_schedule_button_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_schedule_button_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsScheduleButtonClicked(
    properties: WorkflowsRunsScheduleButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsScheduleButtonClicked(properties), options);
  }

  /**
   * workflows-runs_schedule_modal_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_schedule_modal_closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsScheduleModalClosed(
    properties: WorkflowsRunsScheduleModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsScheduleModalClosed(properties), options);
  }

  /**
   * workflows-runs_schedule_modal_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_schedule_modal_opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsScheduleModalOpened(
    properties: WorkflowsRunsScheduleModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsScheduleModalOpened(properties), options);
  }

  /**
   * workflows-runs_upcoming_tabitem_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-runs_upcoming_tabitem_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsRunsUpcomingTabitemClicked(
    properties: WorkflowsRunsUpcomingTabitemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsRunsUpcomingTabitemClicked(properties), options);
  }

  /**
   * workflows-view_last_debug_run_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/workflows-view_last_debug_run_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. workflowId)
   * @param options Amplitude event options.
   */
  workflowsViewLastDebugRunClicked(
    properties: WorkflowsViewLastDebugRunClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkflowsViewLastDebugRunClicked(properties), options);
  }

  /**
   * public-data-analytics_suggested_question_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/public-data-analytics_suggested_question_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. question)
   * @param options Amplitude event options.
   */
  publicDataAnalyticsSuggestedQuestionClicked(
    properties: PublicDataAnalyticsSuggestedQuestionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PublicDataAnalyticsSuggestedQuestionClicked(properties), options);
  }

  /**
   * public-data-analytics_modal_banner_sign_up_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/public-data-analytics_modal_banner_sign_up_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties
   * @param options Amplitude event options.
   */
  publicDataAnalyticsModalBannerSignUpClicked(
    properties: PublicDataAnalyticsModalBannerSignUpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PublicDataAnalyticsModalBannerSignUpClicked(properties), options);
  }

  /**
   * public-data-analytics_header_banner_sign_up_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/public-data-analytics_header_banner_sign_up_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties
   * @param options Amplitude event options.
   */
  publicDataAnalyticsHeaderBannerSignUpClicked(
    properties: PublicDataAnalyticsHeaderBannerSignUpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PublicDataAnalyticsHeaderBannerSignUpClicked(properties), options);
  }

  /**
   * public-data-analytics_header_sign_up_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/meskalero/MarkovML/events/main/latest/public-data-analytics_header_sign_up_clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties
   * @param options Amplitude event options.
   */
  publicDataAnalyticsHeaderSignUpClicked(
    properties: PublicDataAnalyticsHeaderSignUpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PublicDataAnalyticsHeaderSignUpClicked(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
