import {
  ModelAppCreate,
  ModelAppCreateProperties,
  ModelAppInfer,
  ModelAppInferOptions,
  ModelAppInferOptionsProperties,
  ModelAppInferProperties,
  ModelAppOpen,
  ModelAppOpenProperties,
  ModelAppSidebarClicked,
  ModelAppSidebarClickedProperties,
  ModelAppViewSample,
  ModelAppViewSampleProperties,
} from '@/main/amplitude';

export const modelAppEvents = {
  openApp: (data: ModelAppOpenProperties) => new ModelAppOpen(data),
  createApp: (data: ModelAppCreateProperties) => new ModelAppCreate(data),
  inferApp: (data: ModelAppInferProperties) => new ModelAppInfer(data),
  inferOptionClicked: (data: ModelAppInferOptionsProperties) => new ModelAppInferOptions(data),
  appSidebarClicked: (data: ModelAppSidebarClickedProperties) => new ModelAppSidebarClicked(data),
  viewSample: (data: ModelAppViewSampleProperties) => new ModelAppViewSample(data),
};
