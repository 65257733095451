import {
  IconAt,
  IconBlockquote,
  IconBold,
  IconH1,
  IconH2,
  IconH3,
  IconItalic,
  IconPhotoUp,
  IconPlayerPlay,
  IconTable,
  IconTrashX,
} from '@tabler/icons-react';
import { Editor, Range } from '@tiptap/core';
import { SnippetAddSlashCommandProperties } from '@/main/amplitude';
import { TabsTypes } from '../../../../../../apps/main/components/snippets/detail/add-charts/AddChartsDrawer';
import { SnippetDetailContext } from '../../../../../../apps/main/components/snippets/detail/context/SnippetContext';
import { IconBlurOn, IconInsights, IconModelApp, IconStorage } from '../../../../Icons';
import { handleInsertFile } from '../resizable-media/resizableMediaMenu.util';
import { getSuggestionOptions } from '../suggestions/suggestion';
import { CommandsList } from './CommandsList';

export interface CommandItem {
  title: string;
  description: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  command: (props: { editor: Editor; range: Range }) => void;
}

interface CommandsSuggestionProps {
  openAddCharts: SnippetDetailContext['openAddCharts'];
  sendEvent: (command: SnippetAddSlashCommandProperties['snippetCommand']) => void;
  showModelOptions?: boolean;
}

export const getCommandsSuggestionOptions = ({
  openAddCharts,
  sendEvent,
  showModelOptions = false,
}: CommandsSuggestionProps) => {
  const openAddChartDrawer = (editor: Editor, range: Range, tabType: TabsTypes) => {
    // Current cursor position - length of text after `/`
    const position = editor.view.state.selection.$anchor.pos - (range.to - range.from) + 1;
    openAddCharts(tabType, position);
    editor.chain().focus().deleteRange(range).run();
  };

  const modelItems = [
    {
      title: 'Evaluations',
      description: 'Add any evaluation',
      icon: IconInsights,
      command: ({ editor, range }: { editor: Editor; range: Range }) => {
        openAddChartDrawer(editor, range, TabsTypes.Evaluations);
        sendEvent('EVALUATIONS');
      },
    },
    {
      title: 'Experiments',
      description: 'Add any experiment',
      icon: IconBlurOn,
      command: ({ editor, range }: { editor: Editor; range: Range }) => {
        openAddChartDrawer(editor, range, TabsTypes.Experiments);
        sendEvent('EXPERIMENTS');
      },
    },
  ];

  return getSuggestionOptions({
    items: ({ query }) =>
      (
        [
          {
            title: 'Text',
            description: 'Add a text block',
            icon: IconBlockquote,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).setTextBlock().run();
              sendEvent('TEXT');
            },
          },
          {
            title: 'Datasets',
            description: 'Add charts related to dataset',
            icon: IconStorage,
            command: ({ editor, range }) => {
              openAddChartDrawer(editor, range, TabsTypes.Dataset);
              sendEvent('DATASETS');
            },
          },
          ...(showModelOptions ? modelItems : []),
          {
            title: 'Model Apps',
            description: 'Add any model app',
            icon: IconModelApp,
            command: ({ editor, range }) => {
              openAddChartDrawer(editor, range, TabsTypes.ModelApps);
              sendEvent('MODEL_APPS');
            },
          },
          {
            title: 'Mention',
            description: 'Mention someone',
            icon: IconAt,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).run();
              const transaction = editor.state.tr.insertText('@');
              editor.view.dispatch(transaction);
              sendEvent('MENTION');
            },
          },
          {
            title: 'Image',
            description: 'Upload an Image',
            icon: IconPhotoUp,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).run();
              handleInsertFile(editor.view, 'image');
              sendEvent('IMAGE/VIDEO');
            },
          },
          {
            title: 'Video',
            description: 'Upload a Video',
            icon: IconPlayerPlay,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).run();
              handleInsertFile(editor.view, 'video');
              sendEvent('IMAGE/VIDEO');
            },
          },
          {
            title: 'Delete',
            description: 'Delete current block',
            icon: IconTrashX,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).deleteNode('draggableBlock').run();
              sendEvent('DELETE');
            },
          },
          {
            title: 'Heading 1',
            description: 'Apply heading 1',
            icon: IconH1,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).setHeading({ level: 1 }).run();
              sendEvent('TEXT_FORMATTING');
            },
          },
          {
            title: 'Heading 2',
            description: 'Apply heading 2',
            icon: IconH2,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).setHeading({ level: 2 }).run();
              sendEvent('TEXT_FORMATTING');
            },
          },
          {
            title: 'Heading 3',
            description: 'Apply heading 3',
            icon: IconH3,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).setHeading({ level: 3 }).run();
              sendEvent('TEXT_FORMATTING');
            },
          },
          {
            title: 'Bold',
            description: 'Toggle bold',
            icon: IconBold,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).toggleBold().run();
              sendEvent('TEXT_FORMATTING');
            },
          },
          {
            title: 'Italics',
            description: 'Toggle italics',
            icon: IconItalic,
            command: ({ editor, range }) => {
              editor.chain().focus().deleteRange(range).toggleItalic().run();
              sendEvent('TEXT_FORMATTING');
            },
          },
          {
            title: 'Table',
            description: 'Insert a table',
            icon: IconTable,
            command: ({ editor, range }) => {
              editor
                .chain()
                .focus()
                .deleteRange(range)
                .insertTable({
                  cols: 3,
                  rows: 3,
                })
                .focus(range.to + 2)
                .run();
              sendEvent('TABLE');
            },
          },
        ] as CommandItem[]
      ).filter(item => item.title.toLowerCase().startsWith(query.toLowerCase())),
    suggestionComponent: CommandsList,
  });
};
