const nullishStrings = ['', 'null', 'undefined', 'nan', 'none', 'na', 'n/a', '-', '--'];

export const isStringNullish = (value: string) => {
  const trimmed = value.trim().toLowerCase();
  return nullishStrings.some(val => val === trimmed);
};

export const getCommaSeparatedNames = (authors: Record<string, any>[]): string =>
  authors
    .map(({ authorInfo }) => {
      if (authorInfo['authorType'] === 'person') {
        return `${authorInfo['firstName']} ${authorInfo['lastName']}`;
      } else if (authorInfo['authorType'] === 'organization') {
        return authorInfo['name'];
      }
      return ''; // Handle cases where author_type is not recognized
    })
    .filter(name => Boolean(name)) // Remove any empty strings
    .join(', ');
