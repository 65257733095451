import { CitationSourceType } from '../../../../../generated/api';

const doiRegex = /^https:\/\/doi\.org\/10\.\d{4,9}\/[-._;()/:A-Z0-9]+$/i;
export const isValidDOI = (url: string): boolean => {
  if (!url) {
    return true;
  }
  return doiRegex.test(url);
};

export const citationLabelMap = {
  [CitationSourceType.JournalArticle]: 'Journal Article',
  [CitationSourceType.Website]: 'Website',
  [CitationSourceType.Others]: 'Other',
  [CitationSourceType.Book]: 'Book',
  [CitationSourceType.NewspaperArticle]: 'Newspaper Article',
  [CitationSourceType.Report]: 'Report',
  [CitationSourceType.Video]: 'Video',
};
