import noop from 'lodash/noop';
import { useState } from 'react';
import { ChatConversationViewModel } from '@/main/components/common/chat-with-data/chat/conversation/utils';
import { MarkovStarsPink } from '@/shared/design-system';
import { Box, Horizontal, Skeleton, Vertical } from '@/shared/design-system/v2';
import { QuestionTypes } from '../../../../../generated/api';
import { ChatPromptEditInput } from '../ChatPromptEditInput';
import { getAnswerContentToCopy, useGetAnswerContent } from '../answers/factory';
import { usePrismStyles, useSkeletonStyles } from './AnswerView.style';
import { AnswerViewFooter } from './AnswerViewFooter';

interface AnswerViewProps {
  resourceId: string;
  conversation: ChatConversationViewModel;
  onExecuteSql?: (sqlQuery: string) => void;
  isExecuteDisabled?: boolean;
  isLatestConversation: boolean;
  handleAnswerEdit: (editedAnswer: string) => Promise<void>;
  isAnswerUpdateInProgress: boolean;
}

export const AnswerView = ({
  resourceId,
  conversation,
  onExecuteSql = noop,
  isExecuteDisabled,
  isLatestConversation,
  handleAnswerEdit,
  isAnswerUpdateInProgress,
}: AnswerViewProps) => {
  const { classes: skeletonClasses } = useSkeletonStyles();
  const { classes: prismClasses } = usePrismStyles();
  const [isEditMode, setIsEditMode] = useState(false);

  const isAnswerEditAllowed =
    isLatestConversation && conversation.questionType === QuestionTypes.Text2sql;

  const handleAnswerEditClick = () => {
    setIsEditMode(true);
  };

  const answer = useGetAnswerContent(
    resourceId,
    conversation.isError ? undefined : conversation,
    onExecuteSql,
    isAnswerEditAllowed,
    handleAnswerEditClick,
    isExecuteDisabled,
    prismClasses,
  );

  const handleSave = async (editedAnswer: string) => {
    if (conversation.answer !== editedAnswer) {
      await handleAnswerEdit(editedAnswer);
    }
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const answerToCopy = !conversation.isError ? getAnswerContentToCopy(conversation) : null;

  return isEditMode ? (
    <ChatPromptEditInput
      onSave={handleSave}
      onCancel={handleCancel}
      defaultValue={conversation.answer}
      isAnswerUpdateInProgress={isAnswerUpdateInProgress}
    />
  ) : (
    <Horizontal maw="100%" align="start" noWrap>
      <Box>
        <MarkovStarsPink />
      </Box>
      {!conversation.isLoading ? (
        <Vertical pt="sm" pb="md" px="md" w="100%" sx={{ borderRadius: '8px' }}>
          {answer}
          <AnswerViewFooter answerToCopy={answerToCopy} />
        </Vertical>
      ) : (
        <Vertical h="100%" w="100%" spacing={12}>
          <Skeleton className={skeletonClasses.root} />
          <Skeleton className={skeletonClasses.root} />
          <Skeleton className={skeletonClasses.root} />
        </Vertical>
      )}
    </Horizontal>
  );
};
