import { MODAL_LEVEL_2_Z_INDEX, openModal } from '@/shared/design-system/v2';
import { DataSourcePreviewContainer } from './DataSourcePreview.container';
import { ResourceUrlPreview } from './ResourceUrlPreview';

interface FolderFilePreviewProps {
  resourceId: string;
  fileType: string;
  fileKey: string;
}

const FolderFilePreview = ({ resourceId, fileType, fileKey }: FolderFilePreviewProps) => {
  if (fileType === 'csv' || fileType === 'tsv') {
    return <DataSourcePreviewContainer resourceId={resourceId} height="80vh" fileKey={fileKey} />;
  }

  return <ResourceUrlPreview fileKey={fileKey} resourceId={resourceId} fileType={fileType} />;
};

export const useFolderFilePreview =
  () => (resourceId: string, fileName: string, fileType: string, fileKey: string) =>
    openModal({
      title: fileName,
      size: '90vw',
      children: <FolderFilePreview resourceId={resourceId} fileType={fileType} fileKey={fileKey} />,
      zIndex: MODAL_LEVEL_2_Z_INDEX,
    });
