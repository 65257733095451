import {
  AppLoaded,
  AppLoadedProperties,
  DashboardViewed,
  DashboardViewedProperties,
  DatasetsViewSample,
  DatasetsViewSampleProperties,
  EvaluationsViewSample,
  EvaluationsViewSampleProperties,
  ExperimentsViewSample,
  ExperimentsViewSampleProperties,
  InviteSendClicked,
  InviteSendClickedProperties,
  ProjectsViewSample,
  ProjectsViewSampleProperties,
  WelcomeModalCloseClicked,
  WelcomeModalPrimaryCtaClicked,
} from '@/main/amplitude';

export const onboardingEvents = {
  welcomeModal: {
    primaryCtaClicked: () => new WelcomeModalPrimaryCtaClicked(),
    closeClicked: () => new WelcomeModalCloseClicked(),
  },
};

export const globalEvents = {
  appLoaded: (data: AppLoadedProperties) => new AppLoaded(data),
  dashboardViewed: (data: DashboardViewedProperties) => new DashboardViewed(data),
  inviteSentClicked: (data: InviteSendClickedProperties) => new InviteSendClicked(data),
};

export const dashboardEvents = {
  datasetSampleClicked: (data: DatasetsViewSampleProperties) => new DatasetsViewSample(data),
  experimentSampleClicked: (data: ExperimentsViewSampleProperties) =>
    new ExperimentsViewSample(data),
  evaluationSampleClicked: (data: EvaluationsViewSampleProperties) =>
    new EvaluationsViewSample(data),
  projectSampleClicked: (data: ProjectsViewSampleProperties) => new ProjectsViewSample(data),
};
