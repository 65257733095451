import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconLanguage } from '@tabler/icons-react';
import { Horizontal, Text, Tooltip, useMarkovTheme } from '../../../../';

interface TranslateProps {
  onClickTranslateControl: (text: string) => void;
  setVisible: (s: boolean) => void;
}

export const Translate = ({ onClickTranslateControl, setVisible }: TranslateProps) => {
  const { editor } = useRichTextEditorContext();
  const theme = useMarkovTheme();

  const handleTranslateClick = () => {
    setVisible(false);
    const { from, to } = editor.state.selection;

    const selectedText = editor.state.doc.textBetween(from, to, ' ');

    onClickTranslateControl(selectedText);
  };

  return (
    <RichTextEditor.Control onClick={handleTranslateClick} p="xs" aria-label="Translate">
      <Tooltip label="Translate the selected content" openDelay={1000}>
        <Horizontal spacing="xs">
          <IconLanguage size={18} color={theme.colors.blue[6]} />
          <Text variant="subTitle04" color="gray.7">
            Translate
          </Text>
        </Horizontal>
      </Tooltip>
    </RichTextEditor.Control>
  );
};
