import { ControlProps, RankedTester, isStringControl, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { ChangeEvent } from 'react';
import { Text, TextInput, useInputState } from '@/shared/design-system/v2';

const TextControlComponent = ({
  label,
  description,
  schema,
  handleChange,
  path,
  required,
  uischema,
  data,
  visible,
  enabled,
  errors,
  config,
}: ControlProps) => {
  const [val, setVal] = useInputState(data);

  if (!visible) {
    return null;
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
    handleChange(path, e.target.value);
  };

  const isViewOnlyForm = config.viewOnly;

  return (
    <TextInput
      ariaLabel={label || uischema.scope}
      defaultValue={schema?.default}
      value={val}
      onChange={onChange}
      label={
        label.length > 0 ? (
          <Text span variant="subTitle02">
            {label}
          </Text>
        ) : null
      }
      description={
        <Text span variant="small02" color="gray.7" pb="sm">
          {description}
        </Text>
      }
      required={required}
      placeholder={uischema.options?.placeholder}
      error={config.isFormDirty ? errors : undefined}
      disabled={!isViewOnlyForm && !enabled}
      readOnly={isViewOnlyForm}
    />
  );
};

export const textControlTester: RankedTester = rankWith(1, (...args) => isStringControl(...args));

export const TextControl = withJsonFormsControlProps(TextControlComponent);
