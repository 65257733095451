import {
  CorrelationIllustration,
  EmbeddingIllustration,
  MissingValuesIllustration,
  TableIllustration,
} from '@/shared/design-system';
import { VisualizationType } from '@/shared/design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { AnalysisStateNames, SegmentType } from '../../../../../../generated/api';
import { useGetDatasetClusterStateQuery } from '../../../../../../queries/datasets/clustering/cluster';
import { useDataSubsetsListQuery } from '../../../../../../queries/datasets/clustering/subsets';
import { useGetCorrelationTypesQuery } from '../../../../../../queries/datasets/statistics/correlation';
import { useGetMissingValuesChartDataQuery } from '../../../../../../queries/datasets/statistics/missing-values';
import {
  useDatasetSummaryStats,
  useVariableLayoutQuery,
} from '../../../../../../queries/datasets/statistics/variables';
import { VIZ_NAMES, getChartsData, getIllustration } from '../util';

export const useGetDataProfileAnalysers = (
  datasetId: string,
  selectedChartName: string,
  selectedSegment: SegmentType,
  selectedVariable: string,
) => {
  const { data: datasetOverview, isLoading: overviewLoading } = useDatasetSummaryStats(
    datasetId,
    selectedSegment,
  );
  const { numRows = 0, insights = [] } = datasetOverview ?? {};
  const overviewType = numRows > 0 ? VIZ_NAMES.DATASET_DATA_PROFILE_SUMMARY : '';
  const overviewChartsList = [];
  if (numRows > 0) {
    overviewChartsList.push(
      getChartsData({
        visualizationType: VisualizationType.DATASET_DATA_PROFILE_SUMMARY,
        title: 'Data Summary',
        subTitle: selectedSegment,
        chart: <TableIllustration />,
        visualizationParameters: { datasetId, segmentType: selectedSegment },
      }),
    );
  }
  if (insights.length > 0) {
    overviewChartsList.push(
      getChartsData({
        visualizationType: VisualizationType.DATASET_DATA_PROFILE_INSIGHTS,
        title: 'Data Intelligence',
        subTitle: selectedSegment,
        chart: <TableIllustration />,
        visualizationParameters: { datasetId, segmentType: selectedSegment },
      }),
    );
  }

  const { data: variableChartsList, isLoading: variableChartsLoading } = useVariableLayoutQuery(
    datasetId,
    selectedSegment,
    selectedVariable,
  );
  const variableType =
    (variableChartsList?.length ?? 0) > 0 ? VIZ_NAMES.DATASET_DATA_PROFILE_VARIABLE : '';
  const variableCharts = variableChartsList?.map(({ title, visualizationId, chartType }) =>
    getChartsData({
      visualizationType: VisualizationType.DATASET_DATA_PROFILE_VARIABLE,
      title,
      subTitle: `${selectedVariable} (${selectedSegment})`,
      chart: getIllustration(chartType),
      visualizationParameters: {
        title,
        datasetId,
        segmentType: selectedSegment,
        visualizationId,
        chartType,
        variableName: selectedVariable,
      },
    }),
  );

  const { data: correlationTypes, isLoading: correlationLoading } = useGetCorrelationTypesQuery(
    datasetId,
    selectedSegment,
  );
  const { analyzedCorrelations = [] } = correlationTypes ?? {};
  const correlationsType =
    analyzedCorrelations.length > 0 ? VIZ_NAMES.DATASET_DATA_PROFILE_CORRELATION : '';
  const correlationCharts = [
    getChartsData({
      visualizationType: VisualizationType.DATASET_DATA_PROFILE_CORRELATION,
      title: 'Correlation',
      subTitle: selectedSegment,
      chart: <CorrelationIllustration />,
      visualizationParameters: {
        datasetId,
        segmentType: selectedSegment,
      },
    }),
  ];

  const { data: missingValuesData, isLoading: missingValuesLoading } =
    useGetMissingValuesChartDataQuery(datasetId, selectedSegment);
  const missingValuesType = missingValuesData ? VIZ_NAMES.DATASET_DATA_PROFILE_MISSING_VALUE : '';
  const missingValuesChart = [
    getChartsData({
      visualizationType: VisualizationType.DATASET_DATA_PROFILE_MISSING_VALUE,
      title: 'Missing Value',
      subTitle: selectedSegment,
      chart: <MissingValuesIllustration />,
      visualizationParameters: { datasetId, segmentType: selectedSegment },
    }),
  ];

  const { data: embeddingData, isLoading: embeddingLoading } =
    useGetDatasetClusterStateQuery(datasetId);
  const { isLoading: embeddingsSubsetsLoading, data: embeddingsSubsets } =
    useDataSubsetsListQuery(datasetId);

  const embeddingType =
    embeddingData?.analysisState === AnalysisStateNames.Completed
      ? VIZ_NAMES.DATASET_EMBEDDINGS
      : '';
  let embeddingChart = [
    getChartsData({
      visualizationType: VisualizationType.DATASET_EMBEDDINGS,
      title: VIZ_NAMES.DATASET_EMBEDDINGS,
      subTitle: 'Full',
      chart: <EmbeddingIllustration />,
      visualizationParameters: { datasetId },
    }),
  ];

  if (embeddingsSubsets) {
    embeddingChart = [
      ...embeddingChart,
      ...embeddingsSubsets?.map(({ subsetId, name }) =>
        getChartsData({
          visualizationType: VisualizationType.DATASET_EMBEDDINGS,
          title: VIZ_NAMES.DATASET_EMBEDDINGS,
          subTitle: name,
          chart: <EmbeddingIllustration />,
          visualizationParameters: { datasetId, subsetId },
        }),
      ),
    ];
  }

  const dataProfileAnalysersTypes = [
    embeddingType,
    overviewType,
    correlationsType,
    variableType,
    missingValuesType,
  ].filter(i => !!i);
  const dataProfileAnalysersCharts = {
    [embeddingType]: embeddingChart,
    [overviewType]: overviewChartsList,
    [correlationsType]: correlationCharts,
    [variableType]: variableCharts,
    [missingValuesType]: missingValuesChart,
  }[selectedChartName];

  return {
    dataProfileAnalysersTypes,
    dataProfileAnalysersCharts,
    isLoading:
      overviewLoading ||
      correlationLoading ||
      missingValuesLoading ||
      variableChartsLoading ||
      embeddingLoading ||
      embeddingsSubsetsLoading,
  };
};
