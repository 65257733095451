/**
 * We have used the amplitude for our product analytics.
 * This is the implementation with Amplitude Typescript SDK
 * In our implementation we have exposed certain methods
 * * Exported Members and Functions
 * 1. initializeAnalytics
 * 2. setUserID
 * 3. setTracking
 * 4. trackEvent
 * 5. identifyUser
 * 6. setUserProperties
 * 7. Event Interface
 * Developers should use these exported members to work with the analytics library
 *
 *
 */
import { Environment, Event, Result, ampli } from '@/main/amplitude';
import { logger } from '@/shared/initializers/logging';
import { isProduction, isStaging } from '@/shared/lib/env.util';
import { splitWithDelimiter } from '@/shared/lib/util';

let ampliLoaded: Promise<void> | undefined;
let ampliInitialized: Promise<Result | void> | undefined;

const EMPTY_VALUE = 'ORGANIC';

/**
 * Function to initializing analytics library
 * Should be called ONCE before logging any event
 * @param {string} userId - (optional) An identifier for this user.
 * @param  {BrowserOptions} option - (optional) Configuration options
 * @todo We can add events to a queue if the library is not initialized and trigger them once it is initialized
 * @returns void
 */
export const loadAnalyticsModule = (): Promise<void> => {
  const appEnvironment: Environment = isProduction()
    ? 'production'
    : isStaging()
    ? 'staging'
    : 'develop';

  ampliLoaded = ampli.load({
    environment: appEnvironment,
    client: {
      configuration: {
        defaultTracking: {
          pageViews: {
            trackOn: 'attribution',
          },
        },
        autocapture: {
          elementInteractions: true,
        },
      },
    },
  }).promise;

  return ampliLoaded;
};

export const initializeAnalytics = (userId: string, email: string): void => {
  ampliInitialized = ampliLoaded
    ?.then(
      () =>
        ampli.identify(userId, { email_domain: splitWithDelimiter(email, '@').pop() ?? '' })
          .promise,
    )
    .catch(() => {
      logger.error('Amplitude inititalization failed');
    });
};

export const sendAnalytics = (event: Event) => {
  ampliInitialized
    ?.then(() => ampli.track(event))
    .catch(() => logger.error('Unable to send analytics event'));
};
