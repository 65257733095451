import { PropsWithChildren, useEffect } from 'react';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import { identifyClarityUser } from '@/shared/initializers/clarity';
import { setSentryUser } from '@/shared/initializers/sentry';
import { registerUserflow } from '@/shared/initializers/userflow';
import { initializeAnalytics } from './analytics';

export const InitializeInstrumentation = ({ children }: PropsWithChildren) => {
  const { userId, name, email } = useAppMetadata();

  useEffect(() => {
    if (userId) {
      // Initializations for third-party apps using user information
      // Initialize analytics
      initializeAnalytics(userId, email);

      // Register user flow event
      registerUserflow(userId, name, email);

      setSentryUser(userId, name, email);

      identifyClarityUser(userId, name);
    }
  }, [userId, name, email]);

  return <>{children}</>;
};
