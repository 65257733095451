import { IconArrowRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { sendAnalytics } from '@/main/initializers/analytics';
import {
  Box,
  Button,
  Card,
  Horizontal,
  NumberInput,
  Text,
  TextInput,
  Vertical,
  useInputState,
} from '@/shared/design-system/v2';
import { modelAppEvents } from '../../../../analytics/modelAppEvents';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { ModelAppStatus, ModelWithAppMetadata } from '../../../../generated/api';
import { CopyToClipboardTooltip } from '../../../common/CopyToClipboardTooltip';
import { DEFAULT_DURATION } from '../../constants';

interface GenerateAppModalProps {
  modelAppDetails?: ModelWithAppMetadata;
  isAppGenerating: boolean;
  generateModelApp: (payload: { appName: string; duration: number }) => void;
}

export const GenerateAppModal = ({
  modelAppDetails,
  isAppGenerating,
  generateModelApp,
}: GenerateAppModalProps) => {
  const { modelAppName = '', modelId = '', datasetId = '', modelAppStatus } = modelAppDetails ?? {};
  const [error, setError] = useState({
    nameError: '',
    durationError: '',
  });
  const [appName, setAppName] = useInputState(modelAppName);
  const [duration, setDuration] = useInputState(DEFAULT_DURATION);

  const { workspaceId } = useAppMetadata();

  const { precision, recall, 'f1-score': f1Score } =
    // TODO: Fix this types once the info is defined from the BE
    (modelAppDetails?.info as any)?.ds_baseline_metrics?.weighted_avg ?? {};

  const isAppStopped = modelAppStatus === ModelAppStatus.Stopped;
  const isBaselineReady = modelAppStatus === ModelAppStatus.Initialized || ModelAppStatus.Running;

  const cardInfo = [
    { key: 'Precision', value: precision ? precision.toFixed(2) : '-' },
    { key: 'Recall', value: recall ? recall.toFixed(2) : '-' },
    { key: 'F1 Score', value: f1Score ? f1Score.toFixed(2) : '-' },
  ];

  const handleGenerateApp = () => {
    generateModelApp({ appName, duration });
    if (!isAppStopped) {
      sendAnalytics(
        modelAppEvents.createApp({
          pageSource: modelId ? 'MODEL_LIST' : 'DATASET_DETAILS',
          workspaceId,
        }),
      );
    }
  };

  useEffect(() => {
    const isNameInvalid = appName.length > 32;
    const isDurationInvalid = duration && Number(duration) <= 0;

    setError({
      nameError: !isAppStopped && isNameInvalid ? 'Name should be less than 32 chars.' : '',
      durationError: isDurationInvalid ? 'Duration should be greater than zero.' : '',
    });
  }, [appName, duration, setError, isAppStopped]);

  useEffect(() => {
    if (isAppStopped && modelAppName) {
      setAppName(modelAppName);
    }
  }, [isAppStopped, modelAppName, setAppName]);

  return (
    <Vertical spacing="xl">
      <Card bg="yellow.0">
        <Vertical>
          <Box>
            <Text variant="small01" color="gray.6">
              {modelId ? 'Model ID:' : 'Dataset ID:'}
            </Text>
            <Horizontal spacing="xs">
              <Text variant="small01" color="gray.7">
                {modelId ? modelId : datasetId}
              </Text>
              <CopyToClipboardTooltip valueToCopy={modelId ? modelId : datasetId ?? ''} />
            </Horizontal>
          </Box>

          <Horizontal>
            {cardInfo.map(({ key, value }) => (
              <Box key={key}>
                <Text variant="small01" color="gray.6">
                  {key}:
                </Text>

                <Text variant="small01" color="gray.7">
                  {value}
                </Text>
              </Box>
            ))}
          </Horizontal>
        </Vertical>
      </Card>
      <TextInput
        value={appName}
        onChange={setAppName}
        autoFocus
        label="App Name"
        placeholder="Untitled App"
        ariaLabel="app-name"
        required
        disabled={Boolean(isAppStopped && modelAppName)}
        error={error.nameError}
      />
      <NumberInput
        value={duration}
        onChange={setDuration}
        label="Duration (Mins)"
        rightSection={
          <Text mr="md" variant="small01" color="gray.5">
            Mins
          </Text>
        }
        ariaLabel="duration"
        placeholder="Duration in minutes"
        description="Duration for which you want to keep the app live"
        required
        error={error.durationError}
      />
      <Button
        onClick={handleGenerateApp}
        disabled={!appName || !duration || !!error.durationError || !!error.nameError}
        loading={isAppGenerating}
        loaderPosition="right"
        size="md"
        fullWidth
        variant="primary"
        rightIcon={<IconArrowRight size={16} />}
      >
        {isAppStopped
          ? 'Restart App'
          : isBaselineReady
          ? 'Launch App'
          : 'Create baseline & Generate App'}
      </Button>
    </Vertical>
  );
};
