import { useState } from 'react';
import { ulid } from 'ulid';
import {
  Box,
  Center,
  FileWithPath,
  Loader,
  MODAL_LEVEL_2_Z_INDEX,
  Modal,
  Vertical,
  notifications,
  useDisclosure,
  useInputState,
} from '@/shared/design-system/v2';
import { connectorsApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { FileData, useDataUpload } from '../../../../contexts/data-upload/DataUpload';
import { useGetPresignedUrlMutation } from '../../../../queries/data-sources';
import { CompleteParams } from '../../../../queries/data-upload';
import { FilePreview } from './FilePreview';
import { FileUploadInput } from './FileUploadInput';

const fileUploadNotificationId = 'file-upload-notification-id';

export type Step = 'upload' | 'preview';

interface FileUploaderProps {
  onUploadQueued: (uploadId?: string) => void;
  onUploadComplete: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  showPreviewInModal?: boolean;
  supportedFileTypes?: string[];
  additionalData?: Record<string, unknown>;
  isEditable?: boolean;
}

export const FileUploader = ({
  onUploadQueued,
  onUploadComplete,
  showPreviewInModal,
  supportedFileTypes,
  additionalData,
  isEditable = true,
}: FileUploaderProps) => {
  const { workspaceId } = useAppMetadata();
  const [file, setFile] = useState<FileWithPath>();
  const [openPreview, { open, close }] = useDisclosure(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useInputState('');
  const { addUpload } = useDataUpload();
  const { isLoading: isGetPresignedUrlLoading, mutateAsync: getPresignedUrl } =
    useGetPresignedUrlMutation();

  const handleBackClick = () => {
    setFile(undefined);
  };

  const handleUploadSuccess = () => {
    notifications.success('File upload successful', {
      autoClose: true,
    });
  };

  const handleUploadError = (err: string) => {
    notifications.error(`Uploading ${file?.name} failed`, {
      autoClose: true,
    });
  };

  const getUrlAndUpload = async () => {
    if (file) {
      const uploadId = ulid();
      addUpload(uploadId, [file], {
        useUploadWindow: true,
        onSuccess: handleUploadSuccess,
        onError: handleUploadError,
        onFileUploadComplete: async (completeParams: CompleteParams) => {
          try {
            await connectorsApi.completeUploadResourceV1(workspaceId, completeParams);
            onUploadComplete('success', completeParams);
          } catch (e) {
            onUploadComplete('error', completeParams);
          }
        },
        fetchPresignedUrls: async (files: File[]): Promise<FileData[]> => {
          let newFile = files[0];

          if (fileName) {
            newFile = new File([file], fileName, {
              type: file.type,
              lastModified: file.lastModified,
            });
          }

          const fileParams = await getPresignedUrl({ file: newFile });
          return [
            {
              file: newFile,
              uploadParams: fileParams.data.response,
            },
          ];
        },
        additionalData,
      });

      close();
      onUploadQueued(uploadId);
    }
  };

  const handleUpload = () => {
    setIsUploading(true);
    getUrlAndUpload();
  };

  const handleFileDrop = (file: FileWithPath) => {
    open();
    setFile(file);
  };

  if (isGetPresignedUrlLoading) {
    notifications.show({
      id: fileUploadNotificationId,
      message: 'Preparing for file upload.',
      variant: 'info',
    });
  }

  if (file && !showPreviewInModal) {
    return (
      <FilePreview
        file={file}
        handleBackClick={handleBackClick}
        setFileName={setFileName}
        isUploading={isUploading}
        handleUpload={handleUpload}
      />
    );
  }

  return (
    <>
      <Vertical spacing="xs">
        <FileUploadInput
          onFileDrop={handleFileDrop}
          supportedFilesTypes={supportedFileTypes}
          isEditable={isEditable}
        />
      </Vertical>
      <Modal
        opened={Boolean(showPreviewInModal && openPreview && file)}
        onClose={close}
        size="auto"
        zIndex={MODAL_LEVEL_2_Z_INDEX}
      >
        <Box h="80vh" w="75vw" sx={{ overflow: 'auto' }}>
          {file ? (
            <FilePreview
              file={file}
              handleBackClick={handleBackClick}
              setFileName={setFileName}
              isUploading={isUploading}
              handleUpload={handleUpload}
            />
          ) : (
            <Center w="100%" h="100%">
              <Loader text="Loading file..." />
            </Center>
          )}
        </Box>
      </Modal>
    </>
  );
};
