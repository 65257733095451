import { ICellRendererParams } from '@ag-grid-community/core';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { AirbyteJobStatus } from '@/main/generated/api';
import { Vertical } from '@/shared/design-system/v2';
import { connectorsApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  useGetAirbyteConnectorJsonQuery,
  useGetAirbyteJobStatusQuery,
  useSyncResourceDataMutation,
} from '../../../queries/connectors';
import { DatePickerModal } from '../../connectors/DatePickerModal';
import { initializeStartDate } from '../../connectors/connector-details/actions/util';
import { isAirbyteStorageType } from '../../connectors/util';
import {
  DEFAULT_ROW_HEIGHT,
  DEFAULT_TABLE_MENU_WIDTH,
} from '../../datasets-table/v2/cell-renderers/util';
import { TableActionMenu } from '../../table-action-menu/TableActionMenu';

interface ActionsRendererProps extends ICellRendererParams {
  data: any;
}
interface Action {
  id: string;
  label: string;
  handler: () => void;
  disabled?: boolean;
}

export const AirbyteActionsRenderer = ({ data }: ActionsRendererProps) => {
  const [hasStartDate, setHasStartDate] = useState(false);
  const { data: resourceData } = useGetAirbyteConnectorJsonQuery(data.connectorType);
  const { workspaceId } = useAppMetadata();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const { data: jobStatusData } = useGetAirbyteJobStatusQuery(data.resourceId, {
    enabled: Boolean(data.resourceId),
  });
  const selectedDate = data?.resourceMetadata?.startDate
    ? new Date(data.resourceMetadata.startDate)
    : initializeStartDate();

  const { mutate: syncResourceData } = useSyncResourceDataMutation();

  const { mutate: changeStartDate } = useMutation((startDate: string) =>
    connectorsApi.updateResourceStartDateV1(workspaceId, data.resourceId, { startDate }),
  );
  const isActionsDisabled = jobStatusData?.jobStatus !== AirbyteJobStatus.Succeeded;

  useEffect(() => {
    const hasStartDate = Object.keys(
      (resourceData as any)?.parameters?.additionalParams || {},
    ).some(key => key.includes('start_date'));
    setHasStartDate(hasStartDate);
  }, [resourceData]);

  if (!data || !isAirbyteStorageType(data.connectorType)) {
    return null;
  }

  const handleDateConfirm = (date: string) => {
    changeStartDate(date);
    setIsUpdateModalOpen(false);
  };

  const openHandleStartDateUpdate = () => {
    if (!isActionsDisabled) {
      setIsUpdateModalOpen(true);
    }
  };

  const handleSyncAction = (resourceId: string) => {
    if (!isActionsDisabled) {
      syncResourceData(resourceId ?? '');
    }
  };

  const actions: Action[] = [
    {
      id: 'sync-action',
      label: 'Sync',
      handler: () => handleSyncAction(data.resourceId),
      disabled: isActionsDisabled,
    },
    ...(hasStartDate
      ? [
          {
            id: 'update-action',
            label: 'Update Start Date',
            handler: openHandleStartDateUpdate,
            disabled: isActionsDisabled,
          },
        ]
      : []),
  ];

  return (
    <Vertical justify="center" h={DEFAULT_ROW_HEIGHT}>
      <TableActionMenu
        actions={actions}
        width={DEFAULT_TABLE_MENU_WIDTH}
        disabled={isActionsDisabled}
      />

      {isUpdateModalOpen && (
        <DatePickerModal
          isModalOpen={isUpdateModalOpen}
          setModalOpen={setIsUpdateModalOpen}
          onConfirm={handleDateConfirm}
          title="Select Start Date"
          initialDate={selectedDate}
        />
      )}
    </Vertical>
  );
};
