import {
  SnippetAddClicked,
  SnippetAddClickedProperties,
  SnippetAddComment,
  SnippetAddCommentProperties,
  SnippetAddItem,
  SnippetAddItemProperties,
  SnippetAddSlashCommand,
  SnippetAddSlashCommandProperties,
  SnippetChangeMode,
  SnippetChangeModeProperties,
  SnippetDelete,
  SnippetDeleteProperties,
  SnippetItemClicked,
  SnippetItemClickedProperties,
  SnippetShareButton,
  SnippetShareButtonProperties,
  SnippetViewAll,
  SnippetViewAllProperties,
  SnippetViewSample,
  SnippetViewSampleProperties,
} from '@/main/amplitude';

export const snippetEvents = {
  list: {
    listViewed: (data: SnippetViewAllProperties) => new SnippetViewAll(data),
    addNewClicked: (data: SnippetAddClickedProperties) => new SnippetAddClicked(data),
    itemClicked: (data: SnippetItemClickedProperties) => new SnippetItemClicked(data),
    viewSample: (data: SnippetViewSampleProperties) => new SnippetViewSample(data),
  },
  snippet: {
    slashCommand: (data: SnippetAddSlashCommandProperties) => new SnippetAddSlashCommand(data),
    addItem: (data: SnippetAddItemProperties) => new SnippetAddItem(data),
    addComment: (data: SnippetAddCommentProperties) => new SnippetAddComment(data),
    modeChange: (data: SnippetChangeModeProperties) => new SnippetChangeMode(data),
    delete: (data: SnippetDeleteProperties) => new SnippetDelete(data),
    share: (data: SnippetShareButtonProperties) => new SnippetShareButton(data),
  },
};
