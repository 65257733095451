import { ChatConversationViewModel } from '@/main/components/common/chat-with-data/chat/conversation/utils';
import { Vertical } from '@/shared/design-system/v2';
import { AnswerViewContainer } from './AnswerView.container';
import { QuestionView } from './QuestionView';

interface ConversationViewProps {
  resourceId: string;
  conversation: ChatConversationViewModel;
  onExecuteSql?: (sqlQuery: string) => void;
  isExecuteDisabled?: boolean;
  isLatestConversation: boolean;
}

export const ConversationView = ({
  resourceId,
  conversation,
  onExecuteSql,
  isExecuteDisabled,
  isLatestConversation,
}: ConversationViewProps) => (
  <Vertical w="100%" spacing="xl">
    <QuestionView conversation={conversation} />
    <AnswerViewContainer
      resourceId={resourceId}
      conversation={conversation}
      onExecuteSql={onExecuteSql}
      isExecuteDisabled={isExecuteDisabled}
      isLatestConversation={isLatestConversation}
    />
  </Vertical>
);
