import { HocuspocusProvider } from '@hocuspocus/provider';
import { Link } from '@mantine/tiptap';
import { Editor as TiptapEditor } from '@tiptap/core';
import { Bold } from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import { Code } from '@tiptap/extension-code';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { Document as TipTapDoc } from '@tiptap/extension-document';
import DropCursor from '@tiptap/extension-dropcursor';
import Focus from '@tiptap/extension-focus';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { HardBreak } from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Placeholder from '@tiptap/extension-placeholder';
import { Strike } from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { TextSelection } from '@tiptap/pm/state';
import { EditorProps } from '@tiptap/pm/view';
import { AnyExtension, Editor, Node, useEditor } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import camelCase from 'lodash/camelCase';
import fromPairs from 'lodash/fromPairs';
import isPlainObject from 'lodash/isPlainObject';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import transform from 'lodash/transform';
import SparkMD5 from 'spark-md5';
import { DIFF_TYPE } from '../../../../../apps/main/components/common/constants';
import { BaseUser } from '../../../../../apps/main/generated/api';
import { CDN_URL } from '../../../../../apps/main/queries/snippets/media-upload';
import { mantineTheme } from '../../theme';
import { markdownUtils } from '../utils/markdown-converter';
import { CustomLink } from './custom-link/extension';
import { CustomListItem } from './custom-list-item/extension';
import { Document } from './document/Document';
import { ChartGridBlock } from './draggable-block/chart-grid/extension';
import { EmbeddingChartBlock } from './draggable-block/embedding-chart/extensions';
import { DraggableBlock } from './draggable-block/ext';
import { Iframe } from './iframe/extension';
import { CustomImage } from './image/extension';
import { MathNode } from './maths/extension';
import { CustomMention } from './mentions/extension';
import { getCitationsIdsArray } from './mkv-reference/MarkovReference.container';
import { MarkovReference } from './mkv-reference/extension';
import { ModelAppBlock } from './model-app/extensions';
import { ParagraphForTable } from './paragraph-for-table/extension';
import { HTMLParagraph } from './paragraph/HTMLParagraph';
import { Paragraph } from './paragraph/Paragraph';
import { ResizableMedia } from './resizable-media/extension';
import { insertFileInEditor } from './resizable-media/resizableMediaMenu.util';
import { SlashCommand } from './slash-command/extension';
import { SupportAttributes } from './support-attributes/extension';
import { Comment } from './text-comments/extension';
import { TextStyle } from './text-style/extention';
import { UniqueID } from './unique-id/extension';
import { UniqueIdV2 } from './unique-id/extension.v2';

interface MentionOptions {
  suggestion: Omit<SuggestionOptions, 'editor'>;
}

export interface CommentOptions {
  onClickComment?: (
    conversationId: string,
    pos: number,
    selection: { from: number; to: number },
  ) => void;
}

export const getAllCitationNodes = (editor: TiptapEditor): string[] => {
  const result: string[] = [];

  editor?.state.doc.descendants(node => {
    if (node.type.name === MarkovReference.name) {
      const citationIdsFromNode: string[] = getCitationsIdsArray(node.attrs.referenceIds);
      citationIdsFromNode.forEach(referenceId => {
        result.push(referenceId);
      });
    }
  });

  return result;
};

export const highlightBorderMap: Record<number, string | undefined> = {
  [DIFF_TYPE.EQUAL]: mantineTheme.colors?.gray ? mantineTheme.colors.gray[3] : undefined,
  [DIFF_TYPE.ADD]: mantineTheme.colors?.green ? mantineTheme.colors.green[3] : undefined,
  [DIFF_TYPE.DELETE]: mantineTheme.colors?.red ? mantineTheme.colors.red[3] : undefined,
};

export const highlightBackgroundMap: Record<number, string | undefined> = {
  [DIFF_TYPE.EQUAL]: mantineTheme.colors?.gray ? mantineTheme.colors.gray[0] : undefined,
  [DIFF_TYPE.ADD]: mantineTheme.colors?.green ? mantineTheme.colors.green[0] : undefined,
  [DIFF_TYPE.DELETE]: mantineTheme.colors?.red ? mantineTheme.colors.red[0] : undefined,
};

export const toCamelCaseKeys = (obj: { [key: string]: any }): { [key: string]: any } =>
  transform(
    obj,
    (result: Record<string, any>, value: any, key: string) => {
      const camelCaseKey = camelCase(key);

      result[camelCaseKey] = isPlainObject(value)
        ? toCamelCaseKeys(value)
        : Array.isArray(value)
        ? value.map((item: any) => (isPlainObject(item) ? toCamelCaseKeys(item) : item))
        : value;

      return result;
    },
    {},
  );

function deepSortObject(obj: any): any {
  if (Array.isArray(obj)) {
    return sortBy(obj.map(deepSortObject));
  } else if (isPlainObject(obj)) {
    return fromPairs(sortBy(toPairs(obj), 0).map(([key, value]) => [key, deepSortObject(value)]));
  }
  return obj;
}

export const hashObject = (obj: any) => {
  const sortedObj = deepSortObject(obj);
  const jsonString = JSON.stringify(sortedObj);
  return SparkMD5.hash(jsonString);
};

interface ExtensionOptions {
  mentionOptions: MentionOptions;
  commandOptions?: MentionOptions;
  isCommentEditor?: boolean;
  commentOptions?: CommentOptions;
  currentUser?: BaseUser;
  provider?: HocuspocusProvider;
}

export const getEditorExtensions = ({
  mentionOptions,
  commandOptions,
  commentOptions,
  isCommentEditor = false,
  currentUser,
  provider,
}: ExtensionOptions) => {
  const extensions = [
    Paragraph,
    Text,
    Link.extend({
      // https://tiptap.dev/docs/editor/api/schema#inclusive
      inclusive: false,
      exitable: true,
    }),
    Bold,
    Italic,
    Underline,
    Strike,
    Code,
    Highlight.configure({ multicolor: true }),
    TextAlign,
    CustomMention.configure({
      HTMLAttributes: {
        class: 'user-mention',
      },
      suggestion: mentionOptions.suggestion,
    }),
  ];

  if (isCommentEditor)
    return [
      Node.create({
        name: 'document',
        topNode: true,
        content: 'paragraph+',
      }),
      Focus.configure({
        className: 'focused-comment-node',
        mode: 'all',
      }),
      Placeholder.configure({
        placeholder: 'Your thoughts...',
      }),
      History,
      ...extensions,
    ];

  return [
    ParagraphForTable,
    Document,
    Heading,
    DraggableBlock,
    ChartGridBlock,
    EmbeddingChartBlock,
    ...extensions,
    Focus.configure({
      className: 'focused-node',
      mode: 'all',
    }),
    BulletList,
    ListItem,
    DropCursor.configure({
      width: 2,
      color: 'blue',
    }),
    Gapcursor,
    ResizableMedia,
    Iframe,
    ModelAppBlock,
    Placeholder.configure({
      placeholder: `Write something here or type '/' for more options...`,
      includeChildren: true,
    }),
    UniqueID.configure({
      types: ['draggableBlock', 'tableHeader', 'tableCell', 'heading'],
      attributeName: 'data-block-id',
    }),
    SlashCommand.configure({
      suggestion: commandOptions?.suggestion,
    }),
    Comment.configure({
      onClick: commentOptions?.onClickComment,
    }),

    Table.configure({
      resizable: true,
    }).extend({
      allowGapCursor: true,
    }),
    TableRow,
    TableHeader.extend({
      content: 'paragraphForTable',
    }),
    TableCell.extend({
      content: 'paragraphForTable',
    }),
    // FIXIT
    ...(provider && currentUser?.name
      ? [
          Collaboration.configure({
            document: provider?.document,
          }),
          CollaborationCursor.configure({
            render: user => {
              const cursor = document.createElement('span');
              cursor.classList.add('collaboration-cursor__caret');
              cursor.setAttribute('style', `border-color: ${user.color}`);

              const label = document.createElement('div');

              label.classList.add('collaboration-cursor__label');

              const img = document.createElement('img');
              img.setAttribute('src', user?.avatar);
              img.setAttribute('style', `width: 24px; height: 24px`);

              label.insertBefore(img, null);
              cursor.insertBefore(label, null);

              return cursor;
            },
            provider,
            user: {
              name: currentUser.name,
              id: currentUser.userId,
              avatar: currentUser.avatar,
            },
          }),
        ]
      : []),
  ];
};

export const editorProps = (): EditorProps => ({
  attributes: {
    class: 'main-editor',
  },
  handleDrop: function (view, event, slice, moved) {
    if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
      const position = coordinates?.pos ?? 0;
      insertFileInEditor(file, view, position);
      return true;
    }

    // Use default behavior
    return false;
  },
  handlePaste: function (view, event) {
    const files = Array.from(event.clipboardData?.files || []);
    for (const file of files) {
      insertFileInEditor(file, view);
    }

    return files.length > 0;
  },

  // Prevent pasting external images
  transformPastedHTML(html) {
    return html.replace(/<img.*?src="(.*?)".*?>/g, function (match, imgSrc) {
      if (imgSrc.indexOf(CDN_URL) === 0) {
        return match; // keep the img
      }
      return ''; // replace it
    });
  },
});

export enum EditorTypes {
  COPY_EDIT = 'COPY_EDIT',
  SUMMARIZATION = 'SUMMARIZATION',
}

export const extensionsMap = {
  [EditorTypes.COPY_EDIT]: [
    Node.create({
      name: 'document',
      topNode: true,
      content: 'pageContainer',
    }),
    HTMLParagraph,
    Text,
    History,
    // Style name at the end is used because style is allowed in this extension.
    TextStyle,
    CustomImage.configure({ inline: true, allowBase64: true }),
    HardBreak,
    CustomListItem,
    BulletList.extend({
      content: '(listItem|bulletList|orderedList)+',
    }),
    OrderedList.extend({
      content: '(listItem|bulletList|orderedList)+',
    }),
    Table.extend({
      // Do not auto-fix the existing table, according to the schema
      extendNodeSchema() {
        return {};
      },
    }),
    TableRow,
    TableHeader,
    TableCell,
    CustomLink,
    SupportAttributes.configure({
      types: [
        Table.name,
        TableRow.name,
        TableCell.name,
        CustomImage.name,
        ListItem.name,
        BulletList.name,
        OrderedList.name,
      ],
      attributes: { 'data-id': null, style: null, value: null, class: null, 'num-id': null },
    }),
    MathNode,
    UniqueIdV2.configure({
      nodeTypes: [HTMLParagraph.name, ListItem.name],
      markTypes: [TextStyle.name, CustomLink.name],
    }),
  ],
  [EditorTypes.SUMMARIZATION]: [
    TipTapDoc,
    Paragraph,
    Heading,
    Text,
    BulletList,
    ListItem,
    UniqueID.configure({
      types: [Paragraph.name],
      attributeName: 'id',
    }),
  ],
};

interface EditorConfigProps {
  extensions?: AnyExtension[];
  disableMediaUpload?: boolean;
  editorClassName?: string;
  editorProps?: EditorProps;
}

export const editorConfig = ({
  extensions = [],
  disableMediaUpload = false,
  editorClassName = 'main-editor',
  editorProps = {},
}: EditorConfigProps = {}): Parameters<typeof useEditor>[0] => ({
  extensions,
  editorProps: {
    attributes: {
      class: editorClassName,
    },
    ...(!disableMediaUpload
      ? {
          handleDrop: function (view, event, slice, moved) {
            if (
              !moved &&
              event.dataTransfer &&
              event.dataTransfer.files &&
              event.dataTransfer.files[0]
            ) {
              const file = event.dataTransfer.files[0];
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
              const position = coordinates?.pos ?? 0;
              insertFileInEditor(file, view, position);
              return true;
            }

            // Use default behavior
            return false;
          },
          handlePaste: function (view, event) {
            const files = Array.from(event.clipboardData?.files || []);
            for (const file of files) {
              insertFileInEditor(file, view);
            }

            return files.length > 0;
          },

          // Prevent pasting external images
          transformPastedHTML(html) {
            return html.replace(/<img.*?src="(.*?)".*?>/g, function (match, imgSrc) {
              if (imgSrc.indexOf(CDN_URL) === 0) {
                return match; // keep the img
              }
              return ''; // replace it
            });
          },
        }
      : {}),
    ...editorProps,
  },
});

export const getSelectedTextFromEditor = (editor: Editor | null) => {
  if (!editor) {
    return '';
  }
  const { state } = editor;
  const { from, to } = state.selection;
  return state.doc.textBetween(from, to, ' ');
};

export const getSelectedTextAsMarkdownFromEditor = (editor: Editor | null) => {
  if (!editor) {
    return '';
  }
  const { state } = editor;
  const { from, to } = state.selection;
  return markdownUtils.editorToMarkdown(state.doc.cut(from, to));
};

export const replaceSelectedTextFromEditor = (editor: Editor | null, text: string) => {
  if (!editor) {
    return '';
  }

  // https://prosemirror.net/docs/ref/#state.Editor_State

  const { state, schema } = editor;
  const { tr } = state;
  const { $from, $to } = state.selection;

  tr.setSelection(new TextSelection($from, $to));
  const node = schema.nodeFromJSON({
    type: 'text',
    text,
  });
  tr.replaceWith($from.pos, $to.pos, node);

  editor.view.dispatch(tr);
};

export const clearSelection = (editor: Editor | null) => {
  if (!editor) {
    return;
  }

  const { state } = editor;
  const { $from } = state.selection;

  editor.chain().focus().setTextSelection($from.pos).run();
};
