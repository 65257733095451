import { IconFile } from '@tabler/icons-react';
import { Box, Button, Horizontal, Loader, Text, Vertical } from '@/shared/design-system/v2';
import { FileInfoModel } from '../../../generated/api';
import { useGetFolderResourceFilesQuery } from '../../../queries/data-sources';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';
import { useFolderFilePreview } from './use-folder-file-preview';

interface FileListItemProps {
  resourceId: string;
  fileInfo: FileInfoModel;
}

const FileListItem = ({ resourceId, fileInfo }: FileListItemProps) => {
  const openFolderFilePreview = useFolderFilePreview();

  const handlePreviewClick = () => {
    openFolderFilePreview(resourceId, fileInfo.name, fileInfo.type, fileInfo.fileKey);
  };

  return (
    <Horizontal
      key={fileInfo.fileKey}
      p="md"
      sx={theme => ({
        '&:hover': {
          backgroundColor: theme.colors.gray[0],
        },
      })}
    >
      <Horizontal>
        <IconFile />
        <Text variant="bodyShort02" color="gray.8">
          {fileInfo.name}
        </Text>
      </Horizontal>
      <Button ml="auto" onClick={handlePreviewClick}>
        Preview
      </Button>
    </Horizontal>
  );
};

interface FolderPreviewProps {
  resourceId: string;
}

export const FolderPreview = ({ resourceId }: FolderPreviewProps) => {
  const { isLoading, isError, data } = useGetFolderResourceFilesQuery(resourceId);

  if (isLoading) {
    return <Loader text="Loading files..." />;
  }

  if (isError) {
    return <ErrorState title="Error retrieving data sources" />;
  }

  if (!data) {
    return <EmptyState title="No files found in this folder" />;
  }

  return (
    <Box>
      <Text>Files:</Text>
      <Vertical>
        {data.map(fileInfo => (
          <FileListItem key={fileInfo.fileKey} resourceId={resourceId} fileInfo={fileInfo} />
        ))}
      </Vertical>
    </Box>
  );
};
