import first from 'lodash/first';
import { Table, TableThemes } from '@/shared/design-system/v2/core/data-display/table';

export const TABLE_ROW_HEIGHT = 55;
export const TABLE_HEADER_HEIGHT = 60;
export const TABLE_MAX_VIEW_HEIGHT = 550;

interface TableViewProps {
  content: Record<string, unknown>[];
}

export const TableView = ({ content }: TableViewProps) => {
  const firstRow = first(content);
  const columnKeys = firstRow ? Object.keys(firstRow) : [];

  const columns = columnKeys.map(cl => ({
    colId: cl,
    field: cl,
    filter: false,
    sortable: false,
    minWidth: 100,
    flex: Number(columnKeys.length < 5),
  }));

  return (
    <Table
      theme={TableThemes.THEME_V2}
      columns={columns}
      rowData={content}
      paginationPageSize={content.length}
      enableCellTextSelection
      suppressFieldDotNotation
      noRowsOverlayComponent={null}
    />
  );
};
