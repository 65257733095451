import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { Tooltip } from '../../../../';
import { IconRead, IconStopAudio } from '../../../../../Icons';
import { Horizontal, Loader, Text } from '../../../../core';

interface AudioProps {
  onClickAudioControl: (text: string) => void;
  onClickAudioStopControl: () => void;
  isLoadingAudio: boolean;
  isPlayingAudio: boolean;
}

export const AudioPlay = ({
  onClickAudioControl,
  onClickAudioStopControl,
  isLoadingAudio,
  isPlayingAudio,
}: AudioProps) => {
  const { editor } = useRichTextEditorContext();

  const handleAudioClick = () => {
    const { from, to } = editor.state.selection;

    const selectedText = editor.state.doc.textBetween(from, to, ' ');

    onClickAudioControl(selectedText);
  };

  return (
    <Horizontal spacing="xs" align="center">
      {isLoadingAudio ? (
        <Horizontal spacing="xs">
          <Loader aria-label="Loading audio" color="yellow.6" size="xs" />
          <Text variant="subTitle04" color="gray.7">
            Loading
          </Text>
        </Horizontal>
      ) : isPlayingAudio ? (
        <RichTextEditor.Control
          onClick={onClickAudioStopControl}
          p="xs"
          aria-label="Stop Read Aloud"
        >
          <Tooltip label="Stop Read Aloud" openDelay={1000}>
            <Horizontal spacing="xs">
              <IconStopAudio />
              <Text variant="subTitle03" color="gray.7">
                Stop
              </Text>
            </Horizontal>
          </Tooltip>
        </RichTextEditor.Control>
      ) : (
        <RichTextEditor.Control onClick={handleAudioClick} p="xs" aria-label="Read Aloud">
          <Tooltip label="Read Aloud" openDelay={1000}>
            <Horizontal spacing="xs">
              <IconRead />
              <Text variant="subTitle03" color="gray.7">
                Read
              </Text>
            </Horizontal>
          </Tooltip>
        </RichTextEditor.Control>
      )}
    </Horizontal>
  );
};
