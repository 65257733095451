import { openModal } from '@mantine/modals';
import { Box, Text } from '@/shared/design-system/v2';
import { ImagePreview } from './ImagePreview';
import { PDFPreview } from './PDFPreview';
import { TxtHtmlPreview } from './TxtHtmlPreview';
import { VideoPreview } from './VideoPreview';

interface S3FilePreviewProps {
  workflowId: string;
  s3Path: string;
}

// Note: this will change as we will support previews from more providers
const S3FilePreview = ({ workflowId, s3Path }: S3FilePreviewProps) => {
  const splitFileParts = s3Path.split('/');
  const fileName = splitFileParts[splitFileParts.length - 1];

  if (s3Path.endsWith('.pdf')) {
    return (
      <Box>
        <Text variant="subTitle04">{fileName}</Text>
        <PDFPreview url={s3Path} workflowId={workflowId} />
      </Box>
    );
  }

  if (s3Path.endsWith('.txt') || s3Path.endsWith('.html')) {
    return (
      <Box>
        <Text variant="subTitle04">{fileName}</Text>
        <TxtHtmlPreview url={s3Path} workflowId={workflowId} />
      </Box>
    );
  }

  if (s3Path.endsWith('.mp4')) {
    return (
      <Box>
        <Text variant="subTitle04">{fileName}</Text>
        <VideoPreview url={s3Path} workflowId={workflowId} />
      </Box>
    );
  }

  return (
    <Box>
      <Text variant="subTitle04">{fileName}</Text>
      <ImagePreview url={s3Path} workflowId={workflowId} />
    </Box>
  );
};

export const useFilePreview = () => (workflowId: string, value: string) =>
  openModal({
    title: 'Preview',
    size: '75vw',
    mah: '80vh',
    centered: true,
    children: <S3FilePreview workflowId={workflowId} s3Path={value} />,
  });
