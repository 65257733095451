import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconMessagePlus } from '@tabler/icons-react';
import { v4 as uuid } from 'uuid';
import { Horizontal, Text, Tooltip, useMarkovTheme } from '../../../../';

interface CommentProps {
  onClickCommentControl: (conversationId: string) => void;
  compact?: boolean;
}

export const Comment = ({ onClickCommentControl, compact = true }: CommentProps) => {
  const { editor } = useRichTextEditorContext();
  const theme = useMarkovTheme();

  const handleCommentClick = () => {
    const conversationId = uuid();
    editor
      .chain()
      .focus()
      .setComment({
        conversation: conversationId,
      })
      .run();

    onClickCommentControl(conversationId);
  };

  return (
    <RichTextEditor.Control
      active={editor.isActive('block')}
      onClick={handleCommentClick}
      aria-label="Add comment"
      p="xs"
    >
      <Tooltip label="Add comment" openDelay={compact ? 0 : 1000}>
        <Horizontal spacing={compact ? 0 : 'xs'}>
          <IconMessagePlus size={18} color={theme.colors.blue[6]} />
          {!compact && (
            <Text variant="subTitle04" color="gray.7">
              Comment
            </Text>
          )}
        </Horizontal>
      </Tooltip>
    </RichTextEditor.Control>
  );
};
